import { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { api } from '../../utils/ApiMethods'

const useGetConversationsForStore = () => {

    const { auth } = useAuth()

    const [conversation, setConversation] = useState(null)
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(null)
    const [params, setParams] = useState({
        pageNum: 0,
        perPage: 100,
        // sortField: 'created_at',
        filter: '',
        sortDir: -1,
    })

    const handleGetConversation = async (data) => {

        setLoading(true)

        try {

            const response = await api(auth.token).getPost('getConversationForStore').sendJson({
                ...params,
                ...data,
                storeObjectId: auth?.user?.storeData?.objectId
            })

            const result = await response.json()

            if (response.status === 200) {
                const { data, ...rest } = result
                setConversation(data ?? [])
                setPagination(rest ?? [])
            }

            setLoading(false)

        } catch (e) {

            setLoading(false)

        }

    }

    return { handleGetConversation, loading, conversation, pagination, params, setParams }

}

export default useGetConversationsForStore