import { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { errorAlert, successAlert, unauthorizedUser } from '../../utils/AlertToast'
import { api } from '../../utils/ApiMethods'

const useUpdateScheduleStore = () => {

    const { authAction, auth } = useAuth()

    const [loading, setLoading] = useState(false)
    const [days, setDays] = useState(null)

    const handleUpdateScheduleStore = async (days) => {

        setLoading(true)

        try {

            const response = await api(auth.token).post('updateScheduleStore').sendJson({
                storeObjectId: auth?.user?.storeData?.objectId,
                days
            })
            const data = await response.json()

            setLoading(false)

            if (response.status === 200) {

                successAlert('', 'Horario actualizado')
                return true

            }
            else if (response.status === 401)
                unauthorizedUser(authAction, data.message)
            else
                errorAlert('', data.message)

        } catch (e) {

            setLoading(false)
            errorAlert('', 'Error descocido')

        }

        return false

    }

    return { handleUpdateScheduleStore, loading, days, setDays }

}

export default useUpdateScheduleStore