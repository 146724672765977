import { Dialog, Transition } from '@headlessui/react'
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { DotsCircleHorizontalIcon } from '@heroicons/react/solid'
import { Pie } from 'react-chartjs-2'
import { useNavigate } from 'react-router-dom'
import Eye from '../../assets/icons/svg/eye.svg'
import Trash from '../../assets/icons/svg/trash.svg'
import XIcon from '../../assets/icons/svg/x.svg'
import AvatarPhotodefault from '../../assets/images/png/app/AvatarPhotodefault.png'
import { appointmentStatuses } from '../../catalogues/AppointmentStatuses'
import OrderIcon from '../../components/OrderIcon'
import Pagination from '../../components/Pagination'
import SearchInput from '../../components/SearchInput'
import { Table, Td, Th } from '../../components/Table'
import useDeleteAppointment from '../../hooks/appointments/useDeleteAppointment'
import useGetAppointmentsInfo from '../../hooks/appointments/useGetAppointmentsInfo'
import useGetAllPetTypes from '../../hooks/services/useGetAllPetTypes'
import useGetAllServiceTypes from '../../hooks/services/useGetAllServiceTypes'
import useGetAppointments from '../../hooks/services/useGetAppointments'
import useGetServices from '../../hooks/services/useGetServices'
import useUpdateService from '../../hooks/services/useUpdateService'
import { useDebounce } from '../../hooks/utils/useDebounce'
import { classNames } from '../../utils/CssHelpers'

ChartJS.register(ArcElement, Tooltip, Legend);


const spaces = Array(100).fill("\u00A0\u00A0").join("")

const options = {
    plugins: {
        legend: {
            // display: false,
            position: 'right',
            labels: {
                textAlign: 'left',
                boxWidth: 10,
                fontSize: 7,
            },
        },
    },
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
}

const getPicturesData = (data) => (data?.picturesData && data?.picturesData.length > 0) ? data.picturesData[0].fullpath : AvatarPhotodefault

export const HistoricalPage = () => {

    const navigate = useNavigate()
    const getAppointmentsInfo = useGetAppointmentsInfo()
    const getServices = useGetServices()
    const getAppointments = useGetAppointments()
    const deleteAppointment = useDeleteAppointment()
    const getAllServiceTypes = useGetAllServiceTypes()
    const getAllPetTypes = useGetAllPetTypes()
    const updateService = useUpdateService()

    const [search, setSearch] = useState('')
    const fieldSearch = { value: search, onChange: (e) => setSearch(e.target.value) }
    const debouncedSearch = useDebounce(search, 500);

    const [service, setService] = useState(null)
    const [openModalDelete, setOpenModalDelte] = useState(false)
    const cancelButtonModalDeleteRef = useRef(null)

    const showModalDelete = (service) => {
        setService(service)
        setOpenModalDelte(true)
    }

    const formatCurrency = amount => {
        const options = { style: 'currency', currency: 'USD' };
        const numberFormat = new Intl.NumberFormat('en-US', options);

        return numberFormat.format(amount);
    }

    const formatPercentage = percentage => {
        return (percentage * 100).toFixed(2)
    }

    const handleDeleteService = async (objectId) => {
        console.log(objectId);
        const r = await deleteAppointment.handleDeleteAppointment(objectId)
        if (r)
            getServices.setParams({ ...getServices.params })
        setOpenModalDelte(false)
    }

    const updateStatus = async (service) => {
        console.log(service);
        const resp = await updateService.handleUpdateService({
            dataToChange: "isActive",
            serviceObjectId: service.objectId,
            isActive: service.isActive ? 0 : 1
        })
        if (resp)
            getServices.setParams({ ...getServices.params, })
    }

    const onChangueStatus = (e) => {
        const statusFilterAux = e.target.value
        const { statusFilter, ...resp } = getAppointments.params
        getAppointments.setParams({
            ...resp,
            pageNum: 0,
            ...(statusFilterAux !== '' ? { statusFilter: statusFilterAux } : {}),
        })
    }

    const onChangueField = (field) => {
        getAppointments.setParams({
            ...getAppointments.params,
            sortField: field,
            sortDir: getAppointments.params.sortDir * -1
        })
    }

    const onChanguePage = (p) => {
        getAppointments.setParams({
            ...getAppointments.params,
            pageNum: (p - 1)
        })
    }
    const onChangueServiceType = (e) => {
        const serviceTypeFilterAux = e.target.value
        const { serviceTypeFilter, ...resp } = getAppointments.params
        getAppointments.setParams({
            ...resp,
            pageNum: 0,
            ...(serviceTypeFilterAux !== '' ? { serviceTypeFilter: serviceTypeFilterAux } : {}),
        })
    }
    const onChanguePetType = (e) => {
        const petTypeFilterAux = e.target.value
        const { petTypeFilter, ...resp } = getAppointments.params
        getAppointments.setParams({
            ...resp,
            pageNum: 0,
            ...(petTypeFilterAux !== '' ? { petTypeFilter: petTypeFilterAux } : {}),
        })
    }

    const perServiceType = {
        labels: getAllServiceTypes?.types.map(type => {
            const info = getAppointmentsInfo?.info?.perServiceType?.find(perService => {
                return type?.name === perService?.name
            })
            return `${type?.name} ${info ? formatPercentage(info?.percentage) : "0.00"}%${spaces}`
        }),
        datasets: [
            {
                label: '# of Votes',
                data: getAllServiceTypes?.types.map(type => {
                    const info = getAppointmentsInfo?.info?.perServiceType?.find(perService => {
                        return type?.name === perService?.name
                    })
                    return info ? (info?.amount) : 0
                }),
                backgroundColor: [
                    '#FF8A8A',
                    '#80E0FF',
                    '#A698FF',
                    '#75D365',
                ],
                borderColor: [
                    '#FF8A8A',
                    '#80E0FF',
                    '#A698FF',
                    '#75D365',
                ],
                borderWidth: 1,
            },
        ],
    };
    const renderAppointmentStatus = (param) => {
        switch (param) {
            case 0:
                return 'Enviada';
            case 1:
                return 'Aceptada';
            case 2:
                return 'Rechazada';
            case 3:
                return 'Cancelada';
            case 4:
                return 'En Proceso';
            case 5:
                return 'Terminada';
            default:
                return '';
        }
    }
    const perPetType = {
        labels: getAllPetTypes?.types.map(type => {
            const info = getAppointmentsInfo?.info?.perPetType?.find(perPet => {
                return type?.name === perPet?.name
            })
            return `${type?.name} ${info ? formatPercentage(info?.percentage) : "0.00"}%${spaces}`
        }),
        datasets: [
            {
                label: '# of Votes',
                data: getAllPetTypes?.types.map(type => {
                    const info = getAppointmentsInfo?.info?.perPetType?.find(perPet => {
                        return type?.name === perPet?.name
                    })
                    return info ? (info?.amount) : 0
                }),
                backgroundColor: [
                    '#FF8A8A',
                    '#80E0FF',
                    '#A698FF',
                    '#75D365',
                    '#FFE8BA'
                ],
                borderColor: [
                    '#FF8A8A',
                    '#80E0FF',
                    '#A698FF',
                    '#75D365',
                    '#FFE8BA'
                ],
                borderWidth: 1,
            },
        ],
    };

    useEffect(() => {
        getAppointments.handleGetAppointments()
        return () => { };
    }, [getAppointments.params])

    useEffect(() => {
        getAppointments.setParams({
            ...getAppointments.params,
            filter: debouncedSearch,
            pageNum: 0
        })
        return () => { };
    }, [debouncedSearch])

    useEffect(() => {
        getAllServiceTypes.handleGetAllServiceTypes()
        getAppointmentsInfo.handleGetAppointmentsInfo()
        getAllPetTypes.handleGetAllPetTypes()
        return () => { };
    }, [])

    return (
        <div className="flex flex-col">

            <div className="-my-2 sm:-mx-6 lg:-mx-8">

                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">

                    <div className='flex flex-col lg:flex-row mb-5 items-center min-h-52 gap-8'>
                        <div className='lg:w-1/2 w-full'>

                            <div className="mb-8 md:grid md:grid-cols-3 lg:block">
                                <div className="bg-[#FFE8BA] rounded-lg px-6 py-4 mt-5 lg:mr-5 block lg:inline-block">
                                    <p className="text-lg font-semibold leading-6 mb-3">
                                        Servicios agendados
                                    </p>
                                    <h2 className="leading-6 font-bold text-2xl">
                                        {getAppointmentsInfo?.info?.info?.scheduledAppointments ?? 0}
                                    </h2>
                                </div>
                                <div className="bg-[#CEFCFF] rounded-lg px-6 py-4 mt-5 lg:mr-5 block lg:inline-block">
                                    <p className="text-lg font-semibold leading-6 mb-3">
                                        Ganancias globales
                                    </p>
                                    <h2 className="leading-6 font-bold text-2xl">
                                        {formatCurrency(getAppointmentsInfo?.info?.info?.globalIncome ?? 0)}
                                    </h2>
                                </div>
                                <div className="bg-[#D7FED6] rounded-lg px-6 py-4 mt-5 lg:mr-5 block lg:inline-block">
                                    <p className="text-lg font-semibold leading-6 mb-3">
                                        Utilidad global
                                    </p>
                                    <h2 className="leading-6 font-bold text-2xl">
                                        {formatCurrency(getAppointmentsInfo?.info?.info?.globalIncome - (getAppointmentsInfo?.info?.info?.globalUtility ?? 0))}
                                    </h2>
                                </div>
                            </div>
                            <div className="md:w-[28rem] w-full">
                                <SearchInput
                                    className='w-full'
                                    field={fieldSearch}
                                    meta={{ touched: search }}
                                    label='Buscar'
                                ></SearchInput>
                            </div>

                        </div>
                        {/* 
                        <div className="h-full inline-block lg:order-last order-first">
                            <div className="mb-4 h-full inline-block">
                                <Pie data={perPetType} options={options} />
                            </div>
                            <div className="mb-4 h-full inline-block">
                                <Pie data={perServiceType} options={options} />

                            </div>
                        </div> */}

                        <div className='grid grid-cols-1 lg:grid-cols-2 w-full lg:w-1/2'>
                            <div className="mb-4">
                                <Pie data={perPetType} options={options} />
                            </div>
                            <div className="mb-4">
                                <Pie data={perServiceType} options={options} />
                            </div>
                        </div>
                    </div>

                    <div className='grid grid-cols-1 pb-5'>
                        {
                            getAppointments.loading || deleteAppointment.loading ?
                                <div className='flex justify-center mt-20'>
                                    <p className='h2'> Cargando...</p>
                                </div>
                                :
                                <>
                                    {
                                        (getAppointments?.appointments && getAppointments?.appointments?.length !== 0) ?
                                            <Table>
                                                <thead className="">
                                                    <tr>
                                                        <Th className="px-6 min-w-[100px]"
                                                        >
                                                            <OrderIcon
                                                                field={"appointments.identifier"}
                                                                params={getAppointments.params}
                                                                onChange={onChangueField}
                                                            >ID</OrderIcon>
                                                        </Th>
                                                        <Th className="px-6 min-w-[100px]"
                                                        >
                                                            <OrderIcon
                                                                field={"users.name"}
                                                                params={getAppointments.params}
                                                                onChange={onChangueField}
                                                            >Cliente</OrderIcon>

                                                        </Th>
                                                        <Th className="px-6 min-w-[100px]"
                                                        >
                                                            <OrderIcon
                                                                field={"appointments.date"}
                                                                params={getAppointments.params}
                                                                onChange={onChangueField}
                                                            >Fecha y hora</OrderIcon>

                                                        </Th>
                                                        <Th className="px-6 min-w-[100px]"
                                                        >
                                                            <select
                                                                className='multiselect__input bg-tailgreen text-white uppercase font-semibold text-sm pl-0'
                                                                onChange={onChanguePetType}
                                                                value={getAppointments.params.petTypeFilter}
                                                            >
                                                                <option value="" className=''>Tipo de mascota</option>
                                                                {
                                                                    getAllPetTypes.types.map(t => (
                                                                        <option value={t.objectId} key={t.objectId}>{t.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </Th>
                                                        <Th
                                                            className="px-6 min-w-[200px]"
                                                        >
                                                            <OrderIcon
                                                                field={"pets.name"}
                                                                params={getAppointments.params}
                                                                onChange={onChangueField}
                                                            >Mascota</OrderIcon>

                                                        </Th>
                                                        <Th className="px-6 min-w-[100px]"
                                                        >
                                                            <select
                                                                className='multiselect__input bg-tailgreen text-white uppercase font-semibold text-sm pl-0'
                                                                onChange={onChangueServiceType}
                                                                value={getAppointments.params.serviceTypeFilter}
                                                            >
                                                                <option value="" className=''>Categoría de servicio</option>
                                                                {
                                                                    getAllServiceTypes.types.map(t => (
                                                                        <option value={t.objectId} key={t.objectId}>{t.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </Th>
                                                        <Th
                                                            className="px-6 min-w-[200px]"
                                                        >
                                                            <OrderIcon
                                                                field={"appointments.price"}
                                                                params={getAppointments.params}
                                                                onChange={onChangueField}
                                                            >Precio</OrderIcon>

                                                        </Th>
                                                        <Th className="px-6 min-w-[100px]"
                                                        >
                                                            <select
                                                                className='multiselect__input bg-tailgreen text-white uppercase font-semibold text-sm pl-0'
                                                                onChange={onChangueStatus}
                                                                value={getAppointments.params.statusFilter}
                                                            >
                                                                <option value="" className=''>Estatus</option>
                                                                <option value="0" className='option'>Enviada</option>
                                                                <option value="1" className='option'>Aceptada</option>
                                                                <option value="2" className='option'>Rechazada</option>
                                                                <option value="3" className='option'>Cancelada</option>
                                                                <option value="4" className='option'>En proceso</option>
                                                                <option value="5" className='option'>Terminada</option>
                                                            </select>
                                                        </Th>
                                                        <Th className="">

                                                        </Th>
                                                    </tr>
                                                </thead>
                                                <tbody className="">
                                                    {getAppointments?.appointments?.map((appointment, i) => (
                                                        <tr key={appointment.objectId} className="cursor-pointer" onClick={() => navigate(`/historico/${appointment?.objectId}`)}>

                                                            <Td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="">{appointment.identifier}</div>
                                                            </Td>

                                                            <Td className="px-6 py-4">

                                                                <div className="flex items-center">
                                                                    <div className="flex-shrink-0 h-20 w-20">
                                                                        <img
                                                                            className="h-20 w-20 rounded-full object-fill"
                                                                            src={getPicturesData(service)}
                                                                            alt=""
                                                                            onError={(e) => e.target.src = AvatarPhotodefault}
                                                                        />
                                                                    </div>
                                                                    <div className="ml-4">
                                                                        <div className="">{appointment?.userClientData?.name}</div>
                                                                    </div>
                                                                </div>
                                                            </Td>
                                                            <Td className="px-6 py-4 ">
                                                                <div className='min-w-[200px] max-w-[200px] '>{appointment.date}</div>
                                                            </Td>
                                                            <Td className="px-6 py-4  whitespace-nowrap">
                                                                <div className="">{appointment?.petData?.petTypeData?.name}</div>
                                                            </Td>
                                                            <Td className="px-6 py-4 whitespace-nowrap">

                                                                <div className="">{appointment?.petData?.name}</div>
                                                            </Td>
                                                            <Td className="px-6 py-4  whitespace-nowrap">

                                                                <div className="">{appointment?.serviceData?.serviceTypeData?.name}</div>
                                                            </Td>
                                                            <Td className="px-6 py-4 whitespace-nowrap">

                                                                <div className={classNames(
                                                                    appointment?.status === 2 && 'text-tailred'
                                                                )}>{formatCurrency(appointment?.price)}</div>
                                                            </Td>
                                                            <Td className="px-6 py-4  whitespace-nowrap">
                                                                <div className="table-text" style={{
                                                                    padding: '4px 13px', background: '#FFFFFF', border: '1px solid #979797',
                                                                    borderRadius: '12px', display: 'flex', alignItems: 'center', width: 'fit-content'
                                                                }}>
                                                                    <div className='flex items-center justify-center'>
                                                                        <DotsCircleHorizontalIcon className={classNames(
                                                                            'mt-[0.125rem] mb-[0.125rem]',
                                                                            appointment?.status === 4 || appointment?.status === 5 ? 'w-3 h-3 fill-tailgreen mr-1' :
                                                                                appointment?.status === 2 || appointment?.status === 3 ? 'w-3 h-3 fill-tailred mr-1' :
                                                                                    'w-3 h-3 mr-1 fill-tailyellow')} />
                                                                    </div>
                                                                    <div>{renderAppointmentStatus(appointment?.status)}</div>
                                                                </div>
                                                            </Td>
                                                            {/* <Td className="px-6 py-4  whitespace-nowrap">
                                                                <Switch.Group>
                                                                    <div className="flex items-center">
                                                                        <Switch
                                                                            checked={service.isActive}
                                                                            onChange={(v) => updateStatus(service)}
                                                                            className={`${service.isActive ? 'bg-green-600' : 'bg-darkred'
                                                                                } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
                                                                        >
                                                                            <span
                                                                                className={`${service.isActive ? 'translate-x-6' : 'translate-x-1'
                                                                                    } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                                                                            />
                                                                        </Switch>
                                                                        <p className={
                                                                            classNames(
                                                                                "ml-2",
                                                                                service.isActive ? 'text-green-600' : 'text-darkred')
                                                                        }>
                                                                            {service.isActive ? 'Activo' : 'Inactivo'}
                                                                        </p>
                                                                    </div>
                                                                </Switch.Group>
                                                            </Td> */}
                                                            <Td className="px-6 py-4">
                                                                <div className="min-w-[6rem] flex items-center">
                                                                    <img src={Eye} alt="" className='w-6 h-6 mr-6' />
                                                                    <button
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            showModalDelete(appointment)
                                                                        }}
                                                                    >
                                                                        <img src={Trash} alt="" className='w-6 h-6' />
                                                                    </button>
                                                                    {/* <a href="#" className="text-darkgreen hover:text-tailgreen mt-1">
                                                                    </a>
                                                                    <button
                                                                        onClick={() => showModalDelete(service)}
                                                                        className="text-tailgreen hover:text-mediumgreen mt-1"
                                                                    >
                                                                    </button> */}
                                                                </div>
                                                            </Td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                            :
                                            <div className='flex justify-center mt-20'>
                                                <p className='h2'>  No hay resultados</p>
                                            </div>
                                    }

                                    {
                                        (getAppointments.pagination && getAppointments.pagination.last_page !== 1) ?
                                            <Pagination paginate={getAppointments.pagination} onChangue={onChanguePage}></Pagination>
                                            : null
                                    }
                                </>
                        }
                    </div>

                </div>
            </div>

            <Transition.Root show={openModalDelete} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonModalDeleteRef} onClose={setOpenModalDelte}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="bg-[#F9F9F9] px-4 py-4 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <img src={XIcon} alt="" className="w-5 h-5 cursor-pointer" onClick={() => setOpenModalDelte(false)} />
                                </div>
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        {/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                        </div> */}
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full pt-8">
                                            <Dialog.Title as="h3" className="text-center text-2xl leading-6 font-medium text-[#1A051D]">
                                                Eliminar registro
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-center text-base text-[#1A051D] mt-2 pb-8">
                                                    ¿Estás seguro de que quieres eliminar este registro?
                                                </p>
                                            </div>
                                            <div className="flex flex-col w-full">
                                                <button
                                                    type="button"
                                                    className="btn-prymary w-full md:w-[250px] btn-small mx-auto"
                                                    onClick={() => handleDeleteService(service?.objectId)}
                                                >
                                                    Eliminar
                                                </button>
                                                <button
                                                    type="button"
                                                    className="text-tailgreen text-base md:w-[250px] w-full mx-auto py-3 mb-8"
                                                    onClick={() => setOpenModalDelte(false)}
                                                    ref={cancelButtonModalDeleteRef}
                                                >
                                                    Cancelar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )
}