import { XIcon } from '@heroicons/react/outline'
import { ChevronLeftIcon, ChevronRightIcon, PencilIcon, PlusIcon } from '@heroicons/react/solid'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { ReactComponent as ImageDefault } from '../../assets/images/svg/custom/ImageDefault.svg'
import FieldInput from '../../components/FieldInput'
import useCreateService from '../../hooks/services/useCreateService'
import useGetAllPetTypes from '../../hooks/services/useGetAllPetTypes'
import useGetAllServiceTypes from '../../hooks/services/useGetAllServiceTypes'
// import useGetBreedsByPetType from '../../hooks/services/useGetBreedsByPetType'
import { UpdateServiceSchema } from '../../schemas/ServicesSchemas'
import { classNames } from '../../utils/CssHelpers'

import { ReactComponent as AveIcon } from '../../assets/typeservice/Ave.svg'
import { ReactComponent as CerdoIcon } from '../../assets/typeservice/Cerdo.svg'
import { ReactComponent as GatoIcon } from '../../assets/typeservice/Gato.svg'
import { ReactComponent as PerroIcon } from '../../assets/typeservice/Perro.svg'
import { ReactComponent as ReptilIcon } from '../../assets/typeservice/Reptil.svg'
import { Switch } from '@headlessui/react'

const TypesIcons = {
    Ave: AveIcon,
    Cerdo: CerdoIcon,
    Gato: GatoIcon,
    Perro: PerroIcon,
    Reptil: ReptilIcon,
}

const error = msg => <div className='text-darkred font-semibold text-sm mt-1'>{msg}</div>

const maxImgs = 4;

const UpdateType = ({ type, setFieldValue }) => {
    useEffect(() => {
        if (type) {
            setFieldValue('petTypeObjectId', type?.objectId ?? '')
            // setFieldValue('breedObjectId', '')
        }
        return () => { };
    }, [type])
    return null;
}

export const ServiceCreatePage = () => {

    const getAllServiceTypes = useGetAllServiceTypes()
    const getAllPetTypes = useGetAllPetTypes()
    // const getBreedsByPetType = useGetBreedsByPetType()
    const createService = useCreateService()
    let navigate = useNavigate();

    const [imgs, setImgs] = useState([])
    const [position, setPosition] = useState(0)
    const [posEdit, setPosEdit] = useState(null)
    const [typePet, setTypePet] = useState(null)

    const hiddenFileInput = useRef(null);

    const handleClick = () => {
        setPosEdit(posEdit >= 0 ? -1 : (posEdit - 1))
    }
    const createObjFile = (file) => ({ file, url: URL.createObjectURL(file) })
    const handleChange = event => {
        const filesUploaded = event.target.files;
        if (filesUploaded) {
            if (posEdit < 0) {
                const filesimgs = []
                for (let i = 0; i < filesUploaded.length; i++) {
                    const element = filesUploaded[i];
                    if ((imgs.length + filesimgs.length) < maxImgs && element.type.match(/image.*/)) {
                        filesimgs.push(createObjFile(element))
                    }
                }
                setImgs([...imgs, ...filesimgs])
            } else {
                let imgact = filesUploaded[0]
                setImgs(imgs.map((img, i) => (posEdit === i ? createObjFile(imgact) : img)))
                setPosEdit(-1)
            }
        }
        hiddenFileInput.current.value = null;
    };

    const handleDelete = imgaux => setImgs(imgs.filter(img => img !== imgaux))

    useEffect(() => {
        getAllPetTypes.handleGetAllPetTypes()
        getAllServiceTypes.handleGetAllServiceTypes()
        return () => { };
    }, [])

    useEffect(() => {
        if (posEdit !== null)
            hiddenFileInput.current.click();
        return () => { };
    }, [posEdit])

    useEffect(() => {
        if (getAllPetTypes.types.length !== 0) {
            setTypePet(getAllPetTypes.types[0])
        }
        return () => { };
    }, [getAllPetTypes.types])

    // useEffect(() => {
    //     if (typePet) {
    //         console.log("typePet");
    //         console.log(typePet);
    //         getBreedsByPetType.handleGetBreedsByPetType(typePet.objectId)
    //     }
    //     return () => { };
    // }, [typePet])


    const onSubmit = async (values) => {
        const resp = await createService.handleCreateService({
            ...values,
            isFullDay: +values?.isFullDay,
            duration: values?.duration ? values?.duration : 0,
            "pictures[]": imgs.map(f => f.file)
        })
        if (resp)
            navigate("/servicios")
    }

    return (

        <div className="flex flex-col mb-10">

            <Formik
                initialValues={{
                    name: '',
                    description: '',
                    duration: '',
                    price: '',
                    serviceTypeObjectId: '',
                    petTypeObjectId: '',
                    breedObjectId: '',
                    isFullDay: false,
                }}
                validationSchema={UpdateServiceSchema}
                onSubmit={onSubmit}
            >
                {({ setFieldValue, errors, touched, values }) => {

                    return (
                        <Form>
                            <UpdateType
                                type={typePet}
                                setFieldValue={setFieldValue}
                            />
                            <div className="grid grid-cols-1 gap-10 lg:grid-cols-2">
                                <div className='col-span-1'>
                                    <h2 className='h2'>Información del servicio</h2>
                                    <div className='mt-10'>

                                        <Field
                                            name="name"
                                        >
                                            {
                                                (atrs) => <FieldInput
                                                    {...atrs}
                                                    className={"mt-5"}
                                                    label="Nombre o título del servicio"
                                                    type="text"
                                                ></FieldInput>
                                            }
                                        </Field>

                                        <div className='mt-5'>
                                            <label htmlFor="" className="sr-only">
                                                Descripción del servicio
                                            </label>
                                            <Field
                                                name="description"
                                                as="textarea"
                                                className={classNames(
                                                    "inp-small block w-full px-4 py-3 border placeholder-darkgray text-gray-900 focus:outline-none bg-semigray",
                                                    inputBG(touched.description, errors.description)
                                                )}
                                                placeholder="Descripción del servicio"
                                                rows="5"
                                            />

                                            <ErrorMessage name={"description"} render={error} />
                                        </div>

                                        <div className='mt-5 flex flex-row justify-around items-center'>
                                            <p className='text-darkgray font-bold'>Para qué especie</p>
                                            {
                                                getAllPetTypes.types.map(t => {
                                                    const IconA = TypesIcons[t.name]
                                                    return (
                                                        <div key={t.objectId}
                                                            className={classNames(
                                                                'flex flex-col items-center cursor-pointer group'
                                                            )}
                                                            onClick={() => setTypePet(t)}>
                                                            <div
                                                                className={classNames(
                                                                    'h-[100px] w-[100px] border rounded-lg p-5 group',
                                                                    typePet !== t ? 'bg-semigray border-mediumgray' : 'bg-lightgreen border-tailgreen',
                                                                    typePet === t ? '' : 'hover:bg-lightgreen hover:border-tailgreen',
                                                                )}>
                                                                <IconA className={classNames(
                                                                    'h-full w-full',
                                                                    typePet !== t ? 'fill-darkgray' : 'fill-tailgreen',
                                                                    typePet === t ? '' : 'group-hover:fill-tailgreen',
                                                                )}></IconA>
                                                            </div>
                                                            <p className={classNames(
                                                                "font-semibold text-[17px] text-darkgray mt-2"
                                                            )}> {t.name}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        {/* <div className='mt-5'>
                                            <label htmlFor="" className="sr-only">
                                                Seleccione para que tipo de mascota es el servicio
                                            </label>
                                            <Field
                                                as="select"
                                                name="breedObjectId"
                                                className={classNames(
                                                    "form-select inp-small block w-full px-4 py-3 border placeholder-darkgray text-gray-900 focus:outline-none bg-semigray",
                                                    inputBG(touched.breedObjectId, errors.breedObjectId)
                                                )}
                                            >
                                                <option value="">Seleccione para que tipo de mascota es el servicio</option>
                                                {
                                                    getBreedsByPetType.types.map(t => (
                                                        <option value={t.objectId} key={t.objectId}>{t.breedable.name}</option>
                                                    ))
                                                }
                                            </Field>

                                            <ErrorMessage name={"breedObjectId"} render={error} />
                                        </div> */}

                                        <div className='mt-5'>
                                            <label htmlFor="" className="sr-only">
                                                Seleccione la categoría de su servicio
                                            </label>
                                            <Field
                                                as="select"
                                                name="serviceTypeObjectId"
                                                className={classNames(
                                                    "form-select inp-small block w-full px-4 py-3 border placeholder-darkgray text-gray-900 focus:outline-none bg-semigray",
                                                    inputBG(touched.serviceTypeObjectId, errors.serviceTypeObjectId)
                                                )}
                                            >
                                                <option value="">Seleccione la categoría de su servicio</option>
                                                {
                                                    getAllServiceTypes.types.map(t => (
                                                        <option value={t.objectId} key={t.objectId}>{t.name}</option>
                                                    ))
                                                }
                                            </Field>

                                            <ErrorMessage name={"serviceTypeObjectId"} render={error} />
                                        </div>

                                        <Switch.Group>
                                            <div className="flex items-center mt-5">
                                                <Switch
                                                    checked={values?.isFullDay}
                                                    onChange={(value) => setFieldValue('isFullDay', value)}
                                                    className={`${values?.isFullDay ? 'bg-green-600' : 'bg-darkgray'
                                                        } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
                                                >
                                                    <span
                                                        className={`${values?.isFullDay ? 'translate-x-6' : 'translate-x-1'
                                                            } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                                                    />
                                                </Switch>
                                                <p className={
                                                    classNames(
                                                        "ml-2",
                                                        values?.isFullDay ? 'text-green-600' : 'text-darkgray')
                                                }>
                                                    Dura todo el día
                                                </p>
                                            </div>
                                        </Switch.Group>

                                        <Field
                                            name="duration"
                                        >
                                            {
                                                (atrs) => <FieldInput
                                                    {...atrs}
                                                    disabled={values?.isFullDay}
                                                    className={"mt-5"}
                                                    label="Duración en minutos"
                                                    type="number"
                                                ></FieldInput>
                                            }
                                        </Field>

                                        <Field
                                            name="price"
                                        >
                                            {
                                                (atrs) => <FieldInput
                                                    {...atrs}
                                                    className={"mt-5"}
                                                    label="Precio del servicio"
                                                    type="text"
                                                ></FieldInput>
                                            }
                                        </Field>

                                    </div>
                                </div>
                                <div className='col-span-1'>
                                    <h2 className='h2'>Acompaña tu servicio con fotos</h2>
                                    <p className='text-black text-lg mt-5'>Convence a tus clientes potenciales con fotos que luzcan tu servicio.</p>
                                    <div className='flex items-center mt-10 rounded-3xl h-[500px]'>
                                        <div className='flex hover:text-green-300 text-tailgreen' onClick={() => setPosition((!position ? imgs.length : position) - 1)}>
                                            <ChevronLeftIcon className='h-20 w-20'></ChevronLeftIcon>
                                        </div>
                                        <div className='w-full flex'>
                                            {
                                                imgs.length === 0 ?
                                                    <ImageDefault
                                                        className='w-full max-h-[500px] m-auto rounded-2xl fill-mediumgray' />
                                                    :
                                                    <img
                                                        src={(imgs[position % imgs.length].url)}
                                                        className='w-full max-h-[500px] m-auto rounded-2xl' />
                                            }

                                        </div>
                                        <div className='flex hover:text-green-300 text-tailgreen' onClick={() => setPosition((position + 1) % imgs.length)} >
                                            <ChevronRightIcon className='h-20 w-20'></ChevronRightIcon>
                                        </div>

                                    </div>

                                    <div className='flex items-center mt-10 space-x-10 justify-center'>
                                        {
                                            imgs.length !== 0 ? null : error("Agrega una imagen")
                                        }
                                    </div>
                                    <div className='flex items-center mt-10 space-x-10 justify-center'>
                                        <input
                                            type="file"
                                            ref={hiddenFileInput}
                                            onChange={handleChange}
                                            style={{ display: 'none' }}
                                            multiple accept="image/*"
                                        />
                                        {
                                            imgs.length >= maxImgs ? null : (
                                                <div
                                                    onClick={handleClick}
                                                    className='flex bg-gray-100 h-32 w-32 group hover:bg-gray-200 text-tailgreen border-2 border-tailgreen rounded-lg cursor-pointer'>
                                                    <PlusIcon className='w-5 h-5 m-auto group-hover:w-12 group-hover:h-12'></PlusIcon>
                                                </div>
                                            )
                                        }
                                        {
                                            imgs.map((img, i) => (
                                                <div className='h-32 w-32 relative group border-2 border-mediumgray rounded-lg cursor-pointer' onClick={() => setPosition(i)} key={"IMG" + i}>
                                                    <img src={(img.url)} alt="Imagen a selecionar" className='h-32 w-32 object-cover rounded-lg' />

                                                    <div className='absolute top-0 right-0 w-full h-full flex'>
                                                        <div className='w-full m-1 bg-semiwhite my-auto flex flex-row justify-center border rounded-full border-tailgreen space-x-3 cursor-none'>
                                                            <div className='text-tailgreen hover:text-white hover:bg-tailgreen hover:rounded-full p-1' onClick={(e) => { handleDelete(img); e.stopPropagation() }}>
                                                                <XIcon className='w-5 h-5'></XIcon>
                                                            </div>
                                                            <div className='text-tailgreen hover:text-white hover:bg-tailgreen hover:rounded-full p-1' onClick={(e) => { setPosEdit(i); e.stopPropagation() }}>
                                                                <PencilIcon className='w-5 h-5'></PencilIcon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                        {
                                            (new Array((maxImgs - imgs.length > 1 ? (maxImgs - imgs.length - 1) : 0)).fill(0)).map((_, i) => (
                                                <div
                                                    key={"asdas" + i}
                                                    className='flex bg-gray-100 h-32 w-32 border-2 border-mediumgray rounded-lg'>
                                                    <ImageDefault className='w-5 h-5 m-auto fill-mediumgray' />
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className='flex items-center mt-4 space-x-10 justify-center text-[#18A0FB]'>
                                        Las fotografías no deben de pesar más de 250 KB
                                    </div>

                                </div>



                            </div>

                            <div className='flex items-center mt-10 space-x-5 justify-end border-t-2 border-mediumgray py-10'>
                                <NavLink to={`/servicios`}>
                                    <button className='bloc btn-secondary btn-medium w-[250px]'>
                                        Cancelar
                                    </button>
                                </NavLink>
                                <button className='btn-prymary btn-medium w-[250px]' disabled={imgs.length === 0}>Guardar</button>
                            </div>

                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

const inputBG = (touched, error) => (touched) ? (error ?
    "ring-darkred border-darkred focus:border-darkred focus:ring-darkred "
    :
    "ring-tailgreen border-tailgreen focus:border-tailgreen focus:ring-tailgreen"
) : "ring-gray-300 border-gray-300 focus:border-gray-300 focus:ring-gray-300"