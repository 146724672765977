import { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { errorAlert, successAlert } from '../../utils/AlertToast'
import { api } from '../../utils/ApiMethods'

const useDeleteAppointment = () => {

    const [loading, setLoading] = useState(false)
    const { auth } = useAuth()
    const handleDeleteAppointment = async (appointmentObjectId) => {

        console.log(111);
        console.log(111);
        console.log(111);
        setLoading(true)

        try {

            const response = await api(auth.token).post('deleteAppointment').sendJson({ appointmentObjectId })
            const result = await response.json()
            console.log(result);

            setLoading(false)

            if (response.status === 200) {
                successAlert('', result.message)
                return true
            }
            else
                errorAlert('', result.message)

        } catch (e) {

            errorAlert('', 'Error descocido')
            setLoading(false)

        }

        return false

    }

    return { handleDeleteAppointment, loading }

}

export default useDeleteAppointment