import { Dialog, Switch, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'
import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/solid'
import { ReactComponent as Pencil } from '../../assets/icons/svg/pencil2.svg'
import { ReactComponent as Trash } from '../../assets/icons/svg/trash.svg'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import XIcon from '../../assets/icons/svg/x.svg'
import { NavLink } from 'react-router-dom'
import OrderIcon from '../../components/OrderIcon'
import Pagination from '../../components/Pagination'
import SearchInput from '../../components/SearchInput'
import { Table, Td, Th } from '../../components/Table'
import useDeleteService from '../../hooks/services/useDeleteService'
import useGetAllServiceTypes from '../../hooks/services/useGetAllServiceTypes'
import useGetServices from '../../hooks/services/useGetServices'
import useUpdateService from '../../hooks/services/useUpdateService'
import { useDebounce } from '../../hooks/utils/useDebounce'
import AvatarPhotodefault from '../../assets/images/png/app/AvatarPhotodefault.png'
import { classNames } from '../../utils/CssHelpers'

const getPicturesData = (data) => (data?.picturesData && data?.picturesData.length > 0) ? data.picturesData[0].fullpath : AvatarPhotodefault

export const ServicesPage = () => {

    const getServices = useGetServices()
    const deleteService = useDeleteService()
    const getAllServiceTypes = useGetAllServiceTypes()
    const updateService = useUpdateService()

    const [search, setSearch] = useState('')
    const fieldSearch = { value: search, onChange: (e) => setSearch(e.target.value) }
    const debouncedSearch = useDebounce(search, 500);

    const [service, setService] = useState(null)
    const [openModalDelete, setOpenModalDelte] = useState(false)
    const cancelButtonModalDeleteRef = useRef(null)

    const showModalDelete = (service) => {
        setService(service)
        setOpenModalDelte(true)
    }

    const handleDeleteService = async (objectId) => {
        const r = await deleteService.handleDeleteService(objectId)
        if (r)
            getServices.setParams({ ...getServices.params })
        setOpenModalDelte(false)
    }

    const updateStatus = async (service) => {
        console.log(service);
        const resp = await updateService.handleUpdateService({
            dataToChange: "isActive",
            serviceObjectId: service.objectId,
            isActive: service.isActive ? 0 : 1
        })
        if (resp)
            getServices.setParams({ ...getServices.params, })
    }

    const onChangueStatus = (e) => {
        const isActiveFilterAux = e.target.value
        const { isActiveFilter, ...resp } = getServices.params
        getServices.setParams({
            ...resp,
            pageNum: 0,
            ...(isActiveFilterAux !== '' ? { isActiveFilter: isActiveFilterAux } : {}),
        })
    }

    const onChangueField = (field) => {
        getServices.setParams({
            ...getServices.params,
            sortField: field,
            sortDir: getServices.params.sortDir * -1
        })
    }

    const onChanguePage = (p) => {
        getServices.setParams({
            ...getServices.params,
            pageNum: (p - 1)
        })
    }
    const onChangueServiceType = (e) => {
        const serviceTypeFilterAux = e.target.value
        const { serviceTypeFilter, ...resp } = getServices.params
        getServices.setParams({
            ...resp,
            pageNum: 0,
            ...(serviceTypeFilterAux !== '' ? { serviceTypeFilter: serviceTypeFilterAux } : {}),
        })
    }

    useEffect(() => {
        getServices.handleGetServices()
        return () => { };
    }, [getServices.params])

    useEffect(() => {
        getServices.setParams({
            ...getServices.params,
            filter: debouncedSearch,
            pageNum: 0
        })
        return () => { };
    }, [debouncedSearch])

    useEffect(() => {
        getAllServiceTypes.handleGetAllServiceTypes()
        return () => { };
    }, [])

    return (
        <div className="flex flex-col">

            <div className="-my-2 sm:-mx-6 lg:-mx-8">

                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">

                    <div className='flex mb-5 items-center'>

                        <div className='w-2/3 lg:w-1/2'>

                            <SearchInput
                                field={fieldSearch}
                                meta={{ touched: search }}
                                label='Buscar'
                            ></SearchInput>

                        </div>

                        <div className='ml-auto mr-1'>

                            <NavLink
                                to={`/servicios/crear`}
                                className='w-full flex flex-row items-center justify-center group lg:justify-end'>
                                <div className='rounded-full bg-tailgreen p-2 text-white group-hover:bg-mediumgreen'>
                                    <PlusIcon className='w-5 h-5'></PlusIcon>
                                </div>

                                <p
                                    className='text-tailgreen pl-2 text-xl group-hover:text-mediumgreen hidden lg:block'
                                >Agregar servicio</p>
                            </NavLink>
                        </div>

                    </div>

                    <div className='grid grid-cols-1 pb-5'>
                        {
                            getServices.loading || deleteService.loading ?
                                <div className='flex justify-center mt-20'>
                                    <p className='h2'> Cargando...</p>
                                </div>
                                :
                                <>
                                    {
                                        (getServices.services && getServices.services.length !== 0) ?
                                            <Table>
                                                <thead className="">
                                                    <tr>
                                                        <Th className="px-6 min-w-[100px]"
                                                        >
                                                            <OrderIcon
                                                                field={"services.identifier"}
                                                                params={getServices.params}
                                                                onChange={onChangueField}
                                                            >ID</OrderIcon>
                                                        </Th>
                                                        <Th className="px-6 min-w-[100px]"
                                                        >
                                                            <OrderIcon
                                                                field={"services.name"}
                                                                params={getServices.params}
                                                                onChange={onChangueField}
                                                            >Nombre</OrderIcon>

                                                        </Th>
                                                        <Th className="px-6 min-w-[100px]"
                                                        >
                                                            <OrderIcon
                                                                field={"services.description"}
                                                                params={getServices.params}
                                                                onChange={onChangueField}
                                                            >Descripción</OrderIcon>

                                                        </Th>
                                                        <Th className="px-6 min-w-[200px]"
                                                        >
                                                            <OrderIcon
                                                                field={"services.price"}
                                                                params={getServices.params}
                                                                onChange={onChangueField}
                                                            >Precio</OrderIcon>

                                                        </Th>
                                                        <Th
                                                            className="px-6 min-w-[200px]"
                                                        >
                                                            <OrderIcon
                                                                field={"services.duration"}
                                                                params={getServices.params}
                                                                onChange={onChangueField}
                                                            >Duración</OrderIcon>

                                                        </Th>
                                                        <Th className="px-6 min-w-[100px]"
                                                        >
                                                            <select
                                                                className='multiselect__input bg-tailgreen text-white uppercase font-semibold text-sm pl-0'
                                                                onChange={onChangueServiceType}
                                                                value={getServices.params.serviceTypeFilter}
                                                            >
                                                                <option value="" className=''>Categoría</option>
                                                                {
                                                                    getAllServiceTypes.types.map(t => (
                                                                        <option value={t.objectId} key={t.objectId}>{t.name}</option>
                                                                    ))
                                                                }
                                                            </select>

                                                        </Th>
                                                        <Th className="px-6 min-w-[100px]"
                                                        >
                                                            <select
                                                                className='multiselect__input bg-tailgreen text-white uppercase font-semibold text-sm pl-0'
                                                                onChange={onChangueStatus}
                                                                value={getServices.params.isActiveFilter}
                                                            >
                                                                <option value="" className='option'>Estatus</option>
                                                                <option value="1" className='option'>Activo</option>
                                                                <option value="0" className='option'>Inactivo</option>
                                                            </select>

                                                        </Th>
                                                        <Th className="">
                                                        </Th>
                                                    </tr>
                                                </thead>
                                                <tbody className="">
                                                    {getServices.services.map((service, i) => (
                                                        <tr key={service.objectId} className=''>

                                                            <Td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="">{service.identifier}</div>
                                                            </Td>

                                                            <Td className="px-6 py-4">

                                                                <div className="flex items-center">
                                                                    <div className="flex-shrink-0 h-20 w-20">
                                                                        <img
                                                                            className="h-20 w-20 rounded-full object-fill"
                                                                            src={getPicturesData(service)}
                                                                            alt=""
                                                                            onError={(e) => e.target.src = AvatarPhotodefault}
                                                                        />
                                                                    </div>
                                                                    <div className="ml-4">
                                                                        <div className="">{service.name}</div>
                                                                    </div>
                                                                </div>
                                                            </Td>
                                                            <Td className="px-6 py-4 ">
                                                                <div className='min-w-[200px] max-w-[200px] '>{service.description}</div>
                                                            </Td>
                                                            <Td className="px-6 py-4  whitespace-nowrap">
                                                                <div className="">${service.price}</div>
                                                            </Td>
                                                            <Td className="px-6 py-4 whitespace-nowrap">

                                                                <div className="">{service?.isFullDay ? 'Todo el día' : `${service.duration} min`}</div>
                                                            </Td>
                                                            <Td className="px-6 py-4  whitespace-nowrap">

                                                                <div className="">{service.serviceTypeData.name}</div>
                                                            </Td>
                                                            <Td className="px-6 py-4  whitespace-nowrap">
                                                                <Switch.Group>
                                                                    <div className="flex items-center">
                                                                        <Switch
                                                                            checked={service.isActive}
                                                                            onChange={(v) => updateStatus(service)}
                                                                            className={`${service.isActive ? 'bg-green-600' : 'bg-darkred'
                                                                                } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
                                                                        >
                                                                            <span
                                                                                className={`${service.isActive ? 'translate-x-6' : 'translate-x-1'
                                                                                    } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                                                                            />
                                                                        </Switch>
                                                                        <p className={
                                                                            classNames(
                                                                                "ml-2",
                                                                                service.isActive ? 'text-green-600' : 'text-darkred')
                                                                        }>
                                                                            {service.isActive ? 'Activo' : 'Inactivo'}
                                                                        </p>
                                                                    </div>
                                                                </Switch.Group>
                                                            </Td>
                                                            <Td >
                                                                <div className="px-6 py-4 flex flex-row justify-center">
                                                                    <NavLink to={`/servicios/${service?.objectId}/editar`} className="text-darkgreen hover:text-tailgreen mt-1">
                                                                        <Pencil className="fill-[#104936] w-5 h-5 mr-5" />
                                                                    </NavLink>
                                                                    <button
                                                                        onClick={() => showModalDelete(service)}
                                                                        className="text-tailgreen hover:text-mediumgreen mt-1"
                                                                    >
                                                                        <Trash className='w-5 h-5' />
                                                                    </button>
                                                                </div>
                                                            </Td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                            :
                                            <div className='flex justify-center mt-20'>
                                                <p className='h2'>  No hay resultados</p>
                                            </div>
                                    }

                                    {
                                        (getServices.pagination && getServices.pagination.last_page !== 1) ?
                                            <Pagination paginate={getServices.pagination} onChangue={onChanguePage}></Pagination>
                                            : null
                                    }
                                </>
                        }
                    </div>

                </div>
            </div>

            <Transition.Root show={openModalDelete} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonModalDeleteRef} onClose={setOpenModalDelte}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="bg-[#F9F9F9] px-4 py-4 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <img src={XIcon} alt="" className="w-5 h-5 cursor-pointer" onClick={() => setOpenModalDelte(false)} />
                                </div>
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        {/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                        </div> */}
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full pt-8">
                                            <Dialog.Title as="h3" className="text-center text-2xl leading-6 font-medium text-[#1A051D]">
                                                Eliminar servicio
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-center text-base text-[#1A051D] mt-2 pb-8">
                                                    ¿Deseas eliminar el servicio {service?.name}?
                                                </p>
                                            </div>
                                            <div className="flex flex-col w-full">
                                                <button
                                                    type="button"
                                                    className="btn-prymary w-full md:w-[250px] btn-small mx-auto"
                                                    onClick={() => handleDeleteService(service?.objectId)}
                                                >
                                                    Eliminar
                                                </button>
                                                <button
                                                    type="button"
                                                    className="text-tailgreen text-base md:w-[250px] w-full mx-auto py-3 mb-8"
                                                    onClick={() => setOpenModalDelte(false)}
                                                    ref={cancelButtonModalDeleteRef}
                                                >
                                                    Cancelar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )
}