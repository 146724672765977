import React, { useState } from 'react'
import { ModalUpdateShedule } from '../../components/ModalUpdateShedule'
import { MyCalendar } from '../../components/MyCalendar'
import { useDimensions } from '../../utils/Window'

export const HomePage = () => {

    const { dimensions } = useDimensions()
    const isMinWidth = dimensions.width < 1000 ? false : false
    const [scheduleDays, setScheduleDays] = useState()

    return (
        <div className="flex flex-col items-center  p-1 md:p-5 mb-10">
            <MyCalendar isMovil={isMinWidth} scheduleDays={scheduleDays} />
            <ModalUpdateShedule setScheduleDays={setScheduleDays} />
        </div>
    )
}