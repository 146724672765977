import { useEffect, useState } from "react";




const useDimensions = () => {

    const [dimensions, setDimensions] = useState({ width: window.outerWidth, height: window.outerHeight })

    useEffect(() => {
        const update = () => setDimensions({ width: window.outerWidth, height: window.outerHeight })
        window.addEventListener("resize", update);
        return () => window.removeEventListener("resize", update)
    }, [])

    return { dimensions }
}

export { useDimensions }