import { getDayName, getMonthDay } from "../../utils/DateUtils"


export default function DayNames({ heightDays, widthCalendar, days = [] }) {

    const widthDay = widthCalendar / days.length
    const minWidth = widthDay < 1

    return (

        <div className="flex flex-row bg-semigray border-[#E4E3E5] border-b-[1px]" style={{ width: widthCalendar + '%', height: heightDays }}>

            {days.map((day, i) => (
                <div style={{ width: widthDay + '%', height: heightDays }} key={"Day" + day.name + i} className="flex justify-center items-center border-l-[1px] border-[#E4E3E5]">
                    {
                        days.length === 1 ?
                            <>
                                <p className="">{day.name}</p>
                            </>
                            :
                            <>
                                <p className="hidden md:inline">{getDayName(day?.data)} {getMonthDay(day?.data)}</p>
                                <p className="md:hidden inline">{day.name[0]} {getMonthDay(day?.data)}</p>
                            </>
                    }
                </div>
            ))}

        </div>

    )
}
