import { classNames } from "../utils/CssHelpers"
import Pencil from '../assets/icons/svg/pensil.svg'
import FBIcon from '../assets/icons/png/fb-icon.png'
import IGIcon from '../assets/icons/png/ig-icon.png'
import TWIcon from '../assets/icons/png/tw-icon.png'
import TKIcon from '../assets/icons/png/tk-icon.png'
import DefaultCover from '../assets/images/png/app/Default-Cover.png'
import AvatarDefault from '../assets/images/png/app/AvatarPhotodefault.png'
import { useEffect, useState } from "react"
import { Field, Form, Formik } from "formik"
import { UpdateProfileSchema } from "../schemas/StoreSchemas"
import { MiniFieldInput } from "./MiniFieldInput"
import useUpdateStore from "../hooks/stores/useUpdateStore"
import useGetStoreForStore from "../hooks/stores/useGetStoreForStore"
import { StoreMaps } from "./StoreMaps"


export const MySidebar = ({ isOpen, setIsOpen, handleLogout, store, handleGetStoreForStore }) => {
    const [isEditable, setIsEditable] = useState(false)
    const updateStore = useUpdateStore()

    const handleSubmit = async (values) => {
        if (!isEditable) return false
        await updateStore.handleUpdateStore(values)
        setIsEditable(false)
        await handleGetStoreForStore()
    }

    return (
        <section
            className={classNames(
                "overflow-hidden min-h-[calc(100vh-100px)] sm:min-h-[calc(100vh-150px)] lg:min-h-[calc(100vh-120px)] transition-all duration-300 transform absolute left-0 top-[100px] sm:top-[150px] lg:top-[120px] w-full z-10 bg-black bg-opacity-20 flex flex-col",
                isOpen ? 'bg-opacity-20 cursor-pointer' : 'bg-opacity-0 pointer-events-none'
            )}
            onClick={(e) => {
                setIsOpen(false)
            }}
        >
            <Formik
                enableReinitialize={true}
                initialValues={{
                    tradeName: store?.tradeName ?? '',
                    phoneNumber: store?.userProviderData?.phoneNumber ?? '',
                    email: store?.userProviderData?.email ?? '',
                    urlWebSite: store?.urlWebSite ?? '',
                    contactName: store?.contactName ?? '',
                    facebook: store?.facebook ?? '',
                    instagram: store?.instagram ?? '',
                    twitter: store?.twitter ?? '',
                    tiktok: store?.tiktok ?? '',
                    street: store?.commercialAddress?.street ?? '',
                    exteriorNumber: store?.commercialAddress?.exteriorNumber ?? '',
                    interiorNumber: store?.commercialAddress?.interiorNumber ?? '',
                    neighborhood: store?.commercialAddress?.neighborhood ?? '',
                    postalCode: store?.commercialAddress?.postalCode ?? '',
                    municipality: store?.commercialAddress?.municipality ?? '',
                    state: store?.commercialAddress?.state ?? '',
                    country: store?.commercialAddress?.country ?? '',
                    latitude: store?.commercialAddress?.latitude ?? '',
                    longitude: store?.commercialAddress?.longitude ?? ''
                }}
                validationSchema={UpdateProfileSchema}
                onSubmit={handleSubmit}
            >
                {({ errors, setFieldValue, values, submitForm, touched }) => (
                    <Form>
                        <aside
                            onClick={e => e.stopPropagation()}
                            className={classNames(
                                "overflow-hidden h-full right-0 relative md:w-3/4 xl:w-1/2 ml-auto bg-white transform transition-all duration-300 cursor-auto",
                                isOpen ? 'translate-x-0 opacity-100' : 'translate-x-1/3 opacity-0 pointer-events-none'
                            )}
                        >
                            <h2 className="font-semibold text-3xl py-8 px-8">Tu perfil público</h2>
                            <section className="w-full h-[271px] relative px-10 py-4 flex" style={{
                                backgroundImage: `linear-gradient(transparent 55%, rgba(0, 0, 0, 0.7)), url("${values?.picture ? URL.createObjectURL(values.picture) :
                                    store?.pictureData?.fullpath ? store?.pictureData?.fullpath :
                                        DefaultCover
                                    }")`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center center'
                            }}>
                                <div className="absolute rounded-full bg-white right-8 ml-auto p-2 shadow hover:bg-mediumgray">
                                    <label htmlFor="picture">
                                        <img src={Pencil} alt="" className="h-8 w-8 cursor-pointer" />
                                    </label>
                                    <input
                                        type="file"
                                        name="picture"
                                        id="picture"
                                        className="hidden"
                                        onChange={e => {
                                            setFieldValue('picture', e?.target?.files[0])
                                            setIsEditable(true)
                                        }}
                                    />
                                </div>
                                <div className="flex items-end h-full w-full">
                                    <div className="relative w-52 h-52 md:w-40 md:h-40 rounded-full border-8 border-white transform translate-y-1/2 -translate-x-1/2 left-1/2 md:left-0 md:translate-x-0" style={{
                                        backgroundImage: `url(${values?.logo ? URL.createObjectURL(values.logo) :
                                            store?.logopath ? store?.logopath :
                                                AvatarDefault
                                            })`,
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center center'
                                    }}>
                                        <div className="absolute rounded-full bg-white bottom-0 right-0 p-2 shadow hover:bg-mediumgray">
                                            <label htmlFor="logo">
                                                <img src={Pencil} alt="" className="h-8 w-8 cursor-pointer" />
                                            </label>
                                        </div>
                                        <input
                                            type="file"
                                            name="logo"
                                            id="logo"
                                            className="hidden"
                                            onChange={e => {
                                                setFieldValue('logo', e?.target?.files[0])
                                                setIsEditable(true)
                                            }}
                                        />
                                    </div>
                                    <h3
                                        className="hidden md:block text-4xl text-white pl-8 font-bold flex-1" style={{
                                            textShadow: '2px 2px 10px rgba(0, 0, 0, 0.8)'
                                        }}
                                    >
                                        {values?.tradeName ?? ''}
                                    </h3>
                                </div>
                            </section>
                            <section className="p-8 md:p-14 pt-28 md:pt-20 pb-4">
                                <div className="grid md:grid-cols-2 gap-8">
                                    <section>
                                        <h4 className="text-xl font-semibold mb-4 pl-1">Información</h4>
                                        <table className="table-fixed w-full">
                                            <tbody>
                                                <tr>
                                                    <td className="font-semibold p-1 outline-tailgreen">Nombre comercial</td>
                                                    <td className="pl-4">
                                                        <Field name="tradeName">
                                                            {(attr) => <MiniFieldInput disabled={!isEditable} {...attr} />}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                {errors?.tradeName && (
                                                    <tr className="w-full">
                                                        <td></td>
                                                        <td className="w-full pl-4">
                                                            <div className="text-darkred w-full font-semibold text-sm mt-1">{errors?.tradeName}</div>
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr className="">
                                                    <td className="pt-1 font-semibold p-1 outline-tailgreen">Teléfono</td>
                                                    <td className="pl-4 pt-1">
                                                        <Field name="phoneNumber">
                                                            {(attr) => <MiniFieldInput disabled={!isEditable} {...attr} />}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                {errors?.phoneNumber && (
                                                    <tr className="w-full">
                                                        <td colSpan={2} className="w-full">
                                                            <div className="text-right text-darkred w-full font-semibold text-sm mt-1">{errors?.phoneNumber}</div>
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr className="">
                                                    <td className="pt-1 font-semibold p-1 outline-tailgreen">Correo electrónico</td>
                                                    <td className="pl-4 pt-1">
                                                        <Field name="email">
                                                            {(attr) => <MiniFieldInput disabled={!isEditable} {...attr} />}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                {errors?.email && (
                                                    <tr className="w-full">
                                                        <td colSpan={2} className="w-full">
                                                            <div className="text-right text-darkred w-full font-semibold text-sm mt-1">{errors.email}</div>
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr className="">
                                                    <td className="pt-1 font-semibold p-1 outline-tailgreen">Sitio web</td>
                                                    <td className="pl-4 pt-1">
                                                        <Field name="urlWebSite">
                                                            {(attr) => <MiniFieldInput isUrl disabled={!isEditable} {...attr} />}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                {errors?.urlWebSite && (
                                                    <tr className="w-full">
                                                        <td colSpan={2} className="w-full">
                                                            <div className="text-right text-darkred w-full font-semibold text-sm mt-1">{errors?.urlWebSite}</div>
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr className="">
                                                    <td className="pt-1 font-semibold p-1 outline-tailgreen">Contacto</td>
                                                    <td className="pl-4 pt-1">
                                                        <Field name="contactName">
                                                            {(attr) => <MiniFieldInput disabled={!isEditable} {...attr} />}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                {errors?.contactName && (
                                                    <tr className="w-full">
                                                        <td colSpan={2} className="w-full">
                                                            <div className="text-right text-darkred w-full font-semibold text-sm mt-1">{errors?.contactName}</div>
                                                        </td>
                                                    </tr>
                                                )}
                                                {isEditable && (<>
                                                    <tr className="">
                                                        <td className="pt-1 font-semibold p-1 outline-tailgreen">Contraseña</td>
                                                        <td className="pl-4 pt-1">
                                                            <Field name="password">
                                                                {(attr) => <MiniFieldInput type="password" disabled={!isEditable} {...attr} />}
                                                            </Field>
                                                        </td>
                                                    </tr>
                                                    {errors?.password && (
                                                        <tr className="w-full">
                                                            <td colSpan={2} className="w-full">
                                                                <div className="text-right text-darkred w-full font-semibold text-sm mt-1">{errors?.password}</div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    <tr className="">
                                                        <td className="pt-1 font-semibold p-1 outline-tailgreen">Confirmar</td>
                                                        <td className="pl-4 pt-1">
                                                            <Field name="confirmPassword">
                                                                {(attr) => <MiniFieldInput type="password" disabled={!isEditable} {...attr} />}
                                                            </Field>
                                                        </td>
                                                    </tr>
                                                    {(errors?.confirmPassword) && (
                                                        <tr className="w-full">
                                                            <td colSpan={2} className="w-full">
                                                                <div className="text-right text-darkred w-full font-semibold text-sm mt-1">{errors?.confirmPassword}</div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </>)}
                                            </tbody>
                                        </table>
                                    </section>
                                    <section>
                                        <h4 className="text-xl font-semibold mb-4 pl-1">Redes sociales</h4>
                                        <ul>
                                            <li className="font-semibold p-1 text-darkgray flex items-center">
                                                <div className="bg-tailgreen p-2 rounded-md flex items-center justify-center mr-2">
                                                    <img src={FBIcon} alt="" className="w-4 h-4 object-contain" />
                                                </div>
                                                <Field name="facebook">
                                                    {(attr) => <MiniFieldInput disabled={!isEditable} {...attr} />}
                                                </Field>
                                            </li>
                                            <li className="font-semibold p-1 text-darkgray flex items-center">
                                                <div className="bg-tailgreen p-2 rounded-md flex items-center justify-center mr-2">
                                                    <img src={IGIcon} alt="" className="w-4 h-4 object-contain" />
                                                </div>
                                                <Field name="instagram">
                                                    {(attr) => <MiniFieldInput disabled={!isEditable} {...attr} />}
                                                </Field>
                                            </li>
                                            <li className="font-semibold p-1 text-darkgray flex items-center">
                                                <div className="bg-tailgreen p-2 rounded-md flex items-center justify-center mr-2">
                                                    <img src={TWIcon} alt="" className="w-4 h-4 object-contain" />
                                                </div>
                                                <Field name="twitter">
                                                    {(attr) => <MiniFieldInput disabled={!isEditable} {...attr} />}
                                                </Field>
                                            </li>
                                            <li className="font-semibold p-1 text-darkgray flex items-center">
                                                <div className="bg-tailgreen p-2 rounded-md flex items-center justify-center mr-2">
                                                    <img src={TKIcon} alt="" className="w-4 h-4 object-contain" />
                                                </div>
                                                <Field name="tiktok">
                                                    {(attr) => <MiniFieldInput disabled={!isEditable} {...attr} />}
                                                </Field>
                                            </li>
                                        </ul>
                                    </section>
                                    <section>
                                        <h4 className="text-xl font-semibold mb-4 pl-1">Dirección comercial</h4>

                                        <table className="table-fixed w-full">
                                            <tbody>
                                                <tr>
                                                    <td className="font-semibold p-1 outline-tailgreen">Calle</td>
                                                    <td className="pl-4 pt-1">
                                                        <Field name="street">
                                                            {(attr) => <MiniFieldInput disabled={!isEditable} {...attr} />}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                {errors?.street && (
                                                    <tr className="w-full">
                                                        <td></td>
                                                        <td className="w-full pl-4">
                                                            <div className="text-darkred w-full font-semibold text-sm mt-1">{errors?.street}</div>
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <td className="font-semibold p-1 outline-tailgreen">Número exterior</td>
                                                    <td className="pl-4 pt-1">
                                                        <Field name="exteriorNumber">
                                                            {(attr) => <MiniFieldInput disabled={!isEditable} {...attr} />}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                {errors?.exteriorNumber && (
                                                    <tr className="w-full">
                                                        <td></td>
                                                        <td className="w-full pl-4">
                                                            <div className="text-darkred w-full font-semibold text-sm mt-1">{errors?.exteriorNumber}</div>
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <td className="font-semibold p-1 outline-tailgreen">Número interior</td>
                                                    <td className="pl-4 pt-1">
                                                        <Field name="interiorNumber">
                                                            {(attr) => <MiniFieldInput disabled={!isEditable} {...attr} />}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                {errors?.interiorNumber && (
                                                    <tr className="w-full">
                                                        <td></td>
                                                        <td className="w-full pl-4">
                                                            <div className="text-darkred w-full font-semibold text-sm mt-1">{errors?.interiorNumber}</div>
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <td className="font-semibold p-1 outline-tailgreen">Colonia</td>
                                                    <td className="pl-4 pt-1">
                                                        <Field name="neighborhood">
                                                            {(attr) => <MiniFieldInput disabled={!isEditable} {...attr} />}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                {errors?.neighborhood && (
                                                    <tr className="w-full">
                                                        <td></td>
                                                        <td className="w-full pl-4">
                                                            <div className="text-darkred w-full font-semibold text-sm mt-1">{errors?.neighborhood}</div>
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <td className="font-semibold p-1 outline-tailgreen">Código postal</td>
                                                    <td className="pl-4 pt-1">
                                                        <Field name="postalCode">
                                                            {(attr) => <MiniFieldInput disabled={!isEditable} {...attr} />}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                {errors?.postalCode && (
                                                    <tr className="w-full">
                                                        <td></td>
                                                        <td className="w-full pl-4">
                                                            <div className="text-darkred w-full font-semibold text-sm mt-1">{errors?.postalCode}</div>
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <td className="font-semibold p-1 outline-tailgreen">Municipio / alcaldía</td>
                                                    <td className="pl-4 pt-1">
                                                        <Field name="municipality">
                                                            {(attr) => <MiniFieldInput disabled={!isEditable} {...attr} />}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                {errors?.municipality && (
                                                    <tr className="w-full">
                                                        <td></td>
                                                        <td className="w-full pl-4">
                                                            <div className="text-darkred w-full font-semibold text-sm mt-1">{errors?.municipality}</div>
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <td className="font-semibold p-1 outline-tailgreen">Estado</td>
                                                    <td className="pl-4 pt-1">
                                                        <Field name="state">
                                                            {(attr) => <MiniFieldInput disabled={!isEditable} {...attr} />}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                {errors?.state && (
                                                    <tr className="w-full">
                                                        <td></td>
                                                        <td className="w-full pl-4">
                                                            <div className="text-darkred w-full font-semibold text-sm mt-1">{errors?.state}</div>
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <td className="font-semibold p-1 outline-tailgreen">País</td>
                                                    <td className="pl-4 pt-1">
                                                        <Field name="country">
                                                            {(attr) => <MiniFieldInput disabled={!isEditable} {...attr} />}
                                                        </Field>
                                                    </td>
                                                </tr>
                                                {errors?.country && (
                                                    <tr className="w-full">
                                                        <td></td>
                                                        <td className="w-full pl-4">
                                                            <div className="text-darkred w-full font-semibold text-sm mt-1">{errors?.country}</div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        {/* <table className="table-fixed w-full">
                                            <tbody>
                                                <tr>
                                                    <td className="font-semibold p-1">Calle</td>
                                                    <td className="font-semibold p-1 pl-4 text-darkgray">Ejemplo</td>
                                                </tr>
                                                <tr>
                                                    <td className="font-semibold p-1">Número exterior</td>
                                                    <td className="font-semibold p-1 pl-4 text-darkgray">Ejemplo</td>
                                                </tr>
                                                <tr>
                                                    <td className="font-semibold p-1">Número interior</td>
                                                    <td className="font-semibold p-1 pl-4 text-darkgray">Ejemplo</td>
                                                </tr>
                                                <tr>
                                                    <td className="font-semibold p-1">Colonia</td>
                                                    <td className="font-semibold p-1 pl-4 text-darkgray">Ejemplo</td>
                                                </tr>
                                                <tr>
                                                    <td className="font-semibold p-1">Código postal</td>
                                                    <td className="font-semibold p-1 pl-4 text-darkgray">Ejemplo</td>
                                                </tr>
                                                <tr>
                                                    <td className="font-semibold p-1">Municipio/alcaldía</td>
                                                    <td className="font-semibold p-1 pl-4 text-darkgray">Ejemplo</td>
                                                </tr>
                                                <tr>
                                                    <td className="font-semibold p-1">Estado</td>
                                                    <td className="font-semibold p-1 pl-4 text-darkgray">Ejemplo</td>
                                                </tr>
                                                <tr>
                                                    <td className="font-semibold p-1">País</td>
                                                    <td className="font-semibold p-1 pl-4 text-darkgray">Ejemplo</td>
                                                </tr>
                                            </tbody>
                                        </table> */}
                                    </section>
                                    <section className="flex flex-col">
                                        <h4 className="text-xl font-semibold mb-4 pl-1 text-transparent opacity-0">Mapa</h4>
                                        <div className="w-full flex-1">
                                            <StoreMaps setFieldValue={setFieldValue} editable={isEditable} center={{
                                                lat: store?.commercialAddress?.latitude ?? 19.073344219245,
                                                lng: store?.commercialAddress?.longitude ?? -98.25546356592
                                            }} />
                                        </div>
                                    </section>
                                </div>
                                <div className="border-t-[1px] mt-8 border-mediumgray">
                                    <div className="flex flex-col md:flex-row justify-between items-center mt-4">
                                        <button
                                            className="text-xl mt-4 md:mt-0 order-last md:order-first font-semibold pl-1 text-darkgray hover:text-tailgreen"
                                            type="button"
                                            onClick={handleLogout}
                                        >
                                            Cerrar sesión
                                        </button>
                                        {isEditable ? (
                                            <button
                                                className="btn-prymary w-[250px] btn-small"
                                                type="button"
                                                onClick={submitForm}
                                            >
                                                Guardar
                                            </button>
                                        ) : (
                                            <button
                                                className="btn-prymary w-[250px] btn-small"
                                                type="button"
                                                onClick={() => setIsEditable(true)}
                                            >
                                                Editar información
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </section>
                        </aside>
                    </Form>
                )}
            </Formik>
        </section>
    )
}