import 'core-js/es/promise'
import 'core-js/es/set'
import 'core-js/es/map'
import * as Yup from 'yup'

const UpdateServiceSchema = Yup.object().shape({
    name: Yup.string().required('Elemento requerido.'),
    description: Yup.string().required('Elemento requerido.'),
    price: Yup.number().positive("El numero debe ser positivo.").required('Elemento requerido.'),
    serviceTypeObjectId: Yup.string().required('Elemento requerido.'),
    petTypeObjectId: Yup.string().required('Elemento requerido.'),
    // breedObjectId: Yup.string('Formato no válido.').required('Elemento requerido.'),
    isFullDay: Yup.boolean().notRequired(),
    duration: Yup.number()
        .positive('El número debe ser positivo.')
        .when("isFullDay", {
            is: false,
            then: Yup.number().positive('El número debe ser positivo.').required('Elemento requerido.')
        })
})

export { UpdateServiceSchema }