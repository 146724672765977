import { classNames } from "../utils/CssHelpers"
import { useEffect, useState } from "react"
import XIcon from '../assets/icons/svg/x.svg'
import useGetAllNotifications from "../hooks/notifications/useGetAllNotifications"
import useDeleteNotification from "../hooks/notifications/useDeleteNotification"
import useMarkNotificationAsSeen from "../hooks/notifications/useMarkNotificationAsSeen"
import { formatDate } from "../utils/DateUtils"
import InfiniteScroll from 'react-infinite-scroll-component'

const Notification = ({ notification, onDelete, onClick }) => {
    const handleDelete = (e) => {
        e.stopPropagation()
        onDelete()
    }

    return (
        <div
            onClick={onClick}
            className={classNames(
                "flex px-7 py-5 items-start mb-2 relative",
                notification?.seen ? 'bg-semiwhite' : 'bg-white'
            )}
        >
            <div className="pr-4">
                <p className={classNames(
                    "text-base pb-3",
                    notification?.seen ? 'text-darkgray' : 'text-[#1A051D]'
                )}>
                    {notification?.description}
                </p>
                <span className={classNames(
                    "text-sm",
                    notification?.seen ? 'text-darkgray' : 'text-[#5F5F5F]'
                )}>
                    {formatDate(notification?.created_at, 'dd/MM/yyyy, HH:mm')}
                </span>
            </div>
            <button onClick={handleDelete} className="ml-auto">
                <img src={XIcon} alt="" className="w-[0.875rem] h-[0.875rem] block absolute" />
            </button>
        </div>
    )
}

export const MyNotificationPanel = ({ isOpen, setIsOpen, allNotifications }) => {
    const deleteNotification = useDeleteNotification()
    const markAsSeen = useMarkNotificationAsSeen()
    const [notifications, setNotifications] = useState([])

    const restart = () => allNotifications.setParams({
        ...allNotifications.params,
        pageNum: 0
    })

    const handleSeen = async (notification) => {
        const notificationObjectId = notification?.objectId
        if(!notification.seen) {
            await markAsSeen?.handleMarkAsSeen({ notificationObjectId })
            notification.seen = true;
            setNotifications([...notifications]);
        }
    }
    const handleDelete = async (notification) => {
        const notificationObjectId = notification?.objectId
        await deleteNotification?.handleDelete({ notificationObjectId })
        restart();
    }

    const loadNotifications = () => {
        const pagination = allNotifications?.pagination
        const next = pagination?.next_page_url ? true : false
        if (next)
            allNotifications?.setParams({
                ...(allNotifications?.params ?? {}),
                pageNum: (pagination?.current_page)
            })
    }

    useEffect(() => {
        allNotifications?.params?.pageNum == 0 ? setNotifications(notifications => [
            ...(allNotifications?.notifications ?? [])
        ]) :

        setNotifications(notifications => [
            ...(notifications ?? []),
            ...(allNotifications?.notifications ?? [])
        ])

        return () => { }
    }, [allNotifications?.notifications])

    useEffect(() => {
        allNotifications?.handleGetNotifications()
        return () => { }
    }, [allNotifications?.params])

    return (
        <section
            className={classNames(
                "overflow-hidden h-[calc(100vh-100px)] sm:h-[calc(100vh-150px)] lg:h-[calc(100vh-120px)] transition-all duration-300 transform absolute left-0 top-[100px] sm:top-[150px] lg:top-[120px] w-full z-10 bg-black bg-opacity-20 flex flex-wrap flex-col",
                isOpen ? 'bg-opacity-20 cursor-pointer' : 'bg-opacity-0 pointer-events-none'
            )}
            onClick={(e) => {
                setIsOpen(false)
            }}
        >
            <aside
                onClick={e => e.stopPropagation()}
                id="scrollableDiv"
                className={classNames(
                    "h-full max-w-full w-full sm:w-[451px] overflow-y-auto bg-semigray ml-auto transform transition-all duration-300 cursor-auto",
                    isOpen ? 'translate-x-0 opacity-100' : 'translate-x-1/3 opacity-0 pointer-events-none'
                )}
            >
                <section className="h-full">
                    <h2 className="font-semibold text-3xl py-8 px-8">{allNotifications?.loading ? 'Cargando...' : 'Notificaciones'}</h2>
                    <section>
                        <InfiniteScroll
                            dataLength={notifications?.length}
                            next={loadNotifications}
                            hasMore={!!allNotifications?.pagination?.next_page_url}
                            scrollableTarget="scrollableDiv"
                        >
                            {notifications?.map(notification => (
                                <Notification
                                    key={notification?.objectId}
                                    notification={notification}
                                    onClick={() => handleSeen(notification)}
                                    onDelete={() => handleDelete(notification)}
                                />
                            ))}
                        </InfiniteScroll>
                    </section>
                </section>
            </aside>
        </section>
    )
}