import { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { errorAlert, unauthorizedUser } from '../../utils/AlertToast'
import { api } from '../../utils/ApiMethods'

const useGetServiceById = () => {

    const { authAction, auth } = useAuth()

    const [loading, setLoading] = useState(false)
    const [service, setService] = useState({})

    const handleGetServiceById = async (values) => {

        setLoading(true)

        try {

            const response = await api(auth.token).getPost('getServiceById').sendJson({ ...values })
            const data = await response.json()

            setLoading(false)

            if (response.status === 200) {

                setService(data)

            }
            else if (response.status === 401)
                unauthorizedUser(authAction, data.message)
            else
                errorAlert('', data.message)

        } catch (e) {

            setLoading(false)
            errorAlert('', 'Error descocido')

        }
    }

    return { handleGetServiceById, loading, service }

}

export default useGetServiceById