import { Field, Form, Formik } from 'formik'
import React from 'react'
import { NavLink } from 'react-router-dom'
import Logo from '../../assets/images/svg/app/Logo.svg'
import FieldInput from '../../components/FieldInput'
import useLogin from '../../hooks/login/useLogin'
import { LoginSchema } from '../../schemas/SessionSchemas'

export const LoginPage = () => {

    const login = useLogin()

    return (
        <div className="min-h-screen flex items-center justify-center px-4 sm:px-6 lg:px-8 py-12 login">
            <div className="w-full max-w-lg z-10">
                <div>
                    <img
                        className="mx-auto w-auto h-28"
                        src={Logo}
                        alt="Workflow"
                    />
                    {/*  
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        Tails Society
                    </h2>
                    <p className="mt-2 text-center text-sm text-gray-600">
                        Login para  {' '}
                        <a className="font-medium text-indigo-600 hover:text-indigo-500">
                            proveedores
                        </a>
                    </p> 
                    */}
                </div>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                    }}
                    validationSchema={LoginSchema}
                    onSubmit={(values) => login.handleLogin(values)}
                >
                    {({ values, errors }) => (
                        <Form>
                            <div className="mt-20" action="#" method="POST">
                                <input type="hidden" name="remember" defaultValue="true" />
                                <div className="">
                                    <Field
                                        name="email"
                                    >
                                        {
                                            (atrs) => <FieldInput
                                                {...atrs}
                                                className={"mt-5"}
                                                label="Correo"
                                                type="email"
                                            ></FieldInput>
                                        }
                                    </Field>
                                    <Field
                                        name="password"
                                    >
                                        {
                                            (atrs) => <FieldInput
                                                {...atrs}
                                                className={"mt-5"}
                                                label="Contraseña"
                                                type="password"
                                            ></FieldInput>
                                        }
                                    </Field>
                                </div>

                                <div className="flex items-center justify-between mt-2">
                                    <div className="flex items-center">
                                    </div>

                                    <div className="text-sm">
                                        <NavLink
                                            to={'/forgot-password'}
                                            className='w-full flex flex-row items-center justify-center group lg:justify-end'>
                                            <div className="font-medium text-blue-600 hover:text-blue-500">
                                                ¿Olvidaste tu contraseña?
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>

                                <div className='mt-8'>
                                    <button
                                        type="submit"
                                        className="group relative w-full flex justify-center btn-prymary btn-medium"
                                        disabled={login.loading}
                                    >
                                        {
    /*                                         login.loading ?

                                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                                    <LockClosedIcon className="animate-spin h-5 w-5 text-white group-hover:text-white " aria-hidden="true" />
                                                </span>
                                                : null
                                         */}
                                        Iniciar sesión
                                    </button>
                                </div>


                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}