export default function CardContainer({ selectedEvent, top, height, leftCardPart, widthCardPart, render }) {

    return (
        <div
            style={{
                position: 'absolute',
                top: top,
                height: height,
                left: leftCardPart + "%",
                width: widthCardPart + "%",
            }}
            className="z-[9] hover:z-10 hover"
        >

            {render ? render(selectedEvent) : null}

        </div>
    )
}
