import { Dialog, Transition } from '@headlessui/react'
import { CogIcon } from '@heroicons/react/outline'
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { format, subMinutes } from 'date-fns'
import React, { Fragment, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as AveIcon } from '../assets/typeservice/Ave.svg'
import { ReactComponent as CerdoIcon } from '../assets/typeservice/Cerdo.svg'
import { ReactComponent as GatoIcon } from '../assets/typeservice/Gato.svg'
import { ReactComponent as PerroIcon } from '../assets/typeservice/Perro.svg'
import { ReactComponent as ReptilIcon } from '../assets/typeservice/Reptil.svg'
import Calendar from '../components/calendar/Calendar'
import useGetScheduledAppointments from '../hooks/appointments/useGetScheduledAppointments'
import { classNames } from '../utils/CssHelpers'
import { getFullYear, getMonthName, getTime, myFormatDate } from '../utils/DateUtils'
import { capitalize, formatCurrency } from '../utils/StringUtils'
import { addDays, checkt, dateToHour, getInitDayWeekT } from '../utils/Time'
import MyCardContainer from './MyCardContainer'

const TypesIcons = {
    Ave: AveIcon,
    Cerdo: CerdoIcon,
    Gato: GatoIcon,
    Perro: PerroIcon,
    Reptil: ReptilIcon,
}

const daysWeek = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
]

const EventDetailModal = ({ event, onClose }) => {
    const PetIcon = ({ name }) => {
        const Icon = TypesIcons[name]
        return <Icon className="fill-[#716981] h-6 w-6" />
    }

    return (
        <Transition.Root show={!!event} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded text-left overflow-hidden modal-shadow transform transition-all sm:my-8 sm:align-middle sm:max-w-[22rem] sm:w-full">
                            <div className="bg-white px-3 py-5 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    {/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                        </div> */}
                                    <div className="w-full">
                                        <Dialog.Title as="h3" className="text-[1.375rem] leading-6 font-semibold text-black border-b-[1px] border-[#E4E3E5] pt-1 pb-4 px-3">
                                            {event ? (
                                                event?.serviceData?.name
                                            ) : (
                                                <p className='text-white'>a</p>
                                            )}
                                        </Dialog.Title>
                                        <div className="py-4 px-2 border-b-[1px] border-[#E4E3E5]">
                                            <p className="text-xl text-[#716981] p-0 m-0">
                                                {event ? (
                                                    <>
                                                        {capitalize(myFormatDate(new Date(event?.date ?? null), 'EEEE d, LLLL yyyy'))}
                                                    </>
                                                ) : (
                                                    <p className='text-white'>a</p>
                                                )}
                                            </p>
                                        </div>
                                        <div className="flex gap-5">
                                            <div className="py-4 px-2 border-b-[1px] border-[#E4E3E5] flex-1">
                                                <p className="text-xl text-[#716981] p-0 m-0">
                                                    {event ? (
                                                        <>
                                                            {myFormatDate(new Date(event?.date ?? null), 'HH:mm')}

                                                            {!event?.serviceData?.isFullDay && (
                                                                <>
                                                                    {' '}-{' '}
                                                                    {(subMinutes(new Date(event?.date ?? null), (-event?.duration) ?? 0) ?? new Date())?.toLocaleString('es', {
                                                                        hour: '2-digit',
                                                                        minute: '2-digit'
                                                                    })}
                                                                </>
                                                            )}

                                                        </>
                                                    ) : (
                                                        <p className='text-white'>a</p>
                                                    )}
                                                </p>
                                            </div>
                                            <div className="py-4 px-2 border-b-[1px] border-[#E4E3E5] flex-1">
                                                <p className="text-xl text-[#716981] p-0 m-0 text-center">
                                                    {event?.serviceData?.isFullDay ? (
                                                        'Todo el día'
                                                    ) : (
                                                        <>
                                                            {event ? (
                                                                <>
                                                                    {event?.duration} min
                                                                </>
                                                            ) : (
                                                                <p className='text-white'>a</p>
                                                            )}
                                                        </>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="py-4 px-2 border-b-[1px] border-[#E4E3E5] text-xl text-[#716981] flex">
                                            <p className="p-0 m-0 flex-1">
                                                {event ? (
                                                    <>
                                                        {event?.petData?.name ?? ''}
                                                    </>
                                                ) : (
                                                    <p className='text-white'>a</p>
                                                )}
                                            </p>
                                            <span className="p-0 m-0">
                                                {event ? (
                                                    <PetIcon name={event?.petData?.petTypeData?.name} />
                                                ) : (
                                                    <p className='text-white'>a</p>
                                                )}
                                            </span>
                                        </div>
                                        <div className="py-4 px-2 border-b-[1px] border-[#E4E3E5] text-xl text-[#716981] flex">
                                            <p className="p-0 m-0 flex-1">
                                                {event ? (
                                                    <>
                                                        {event?.serviceData?.serviceTypeData?.name ?? ''}
                                                    </>
                                                ) : (
                                                    <p className='text-white'>a</p>
                                                )}
                                            </p>
                                            <span className="p-0 m-0">
                                                {event ? (
                                                    <>
                                                        {formatCurrency(event?.price)}
                                                    </>
                                                ) : (
                                                    <p className='text-white'>a</p>
                                                )}
                                            </span>
                                        </div>
                                        {event?.specialIndications && (
                                            <div className="py-4 px-2 border-b-[1px] border-[#E4E3E5] text-xl text-[#716981] flex">
                                                <p className="p-0 m-0 flex-1">
                                                    {event?.specialIndications}
                                                </p>
                                            </div>
                                        )}
                                        <div className="flex flex-col w-full pt-4">
                                            <NavLink to={`/historico/${event?.objectId}`}>
                                                <button
                                                    type="button"
                                                    className="btn-prymary w-full btn-small mx-auto"
                                                >
                                                    Ver detalles
                                                </button>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
export const MyCalendar = ({ isMovil = false, scheduleDays }) => {
    const [now, setNow] = useState(null)
    const [hoursConfig, setHoursConfig] = useState(null)
    const [events, setEvents] = useState([])
    const [breakTimes, setBreakTimes] = useState([])
    const [selectedEvent, setSelectedEvent] = useState()
    const [breakTime, setBreakTime] = useState([])
    const [days, setDays] = useState([])
    const [hours, setHours] = useState([])

    const { scheduledAppointments, loading, handleGetScheduledAppointments } = useGetScheduledAppointments()

    useEffect(() => {
        setNow(isMovil ? new Date() : getInitDayWeekT())
        return () => { }
    }, [isMovil])

    useEffect(() => {
        if (now) {
            const daysweek = new Array(isMovil ? 1 : 7).fill(0).map((_, i) => addDays(now, i))

            setDays(daysweek.map(d => ({
                name: d.toLocaleString('es', { weekday: 'long' }).toLocaleUpperCase(),
                nameSmall: d.toLocaleString('es', { weekday: 'short' }).toLocaleUpperCase(),
                date: format(d, 'yyyy-MM-dd HH:mm:ss'),
                data: d
            })))
        }
        return () => { }
    }, [now])

    useEffect(() => {
        if (days) {
            handleGetScheduledAppointments({
                startDate: days[0]?.date,
                endDate: days?.at(-1)?.date,
            })
        }
        return () => { }
    }, [days])

    useEffect(() => {
        // if (selectedEvent) {
        //     console.log(selectedEvent)
        // } else {
        //     console.log('Se cerró')
        // }
    }, [selectedEvent])

    useEffect(() => {
        let max = 0, min = 24;
        let schDays = []

        Object.keys(scheduleDays ?? {})?.forEach((weekDay) => {

            const scheduleDay = scheduleDays[weekDay]
            const i = daysWeek.indexOf(weekDay)
            const today = myFormatDate(new Date(), 'yyyy-MM-dd')
            const dateStart = `${today} ${scheduleDay?.breakStartTime}`
            const dateEnd = `${today} ${scheduleDay?.breakEndTime}`
            const start = new Date(dateStart);
            const end = new Date(dateEnd);

            const hs = dateToHour(start);
            const he = dateToHour(end);
            const diff = he - hs

            max = he > max ? he : max
            min = hs < min ? hs : min

            const schDay = ({
                start: hs,
                diff: diff,
                numDay: i,
                pwidth: 1,
                render: () => (
                    (scheduleDay?.isActive && scheduleDay?.breakStartTime && scheduleDay?.breakEndTime) ? (
                        <div className="h-full w-full flex flex-col">
                            <div className='border-t-[1px] border-[#716981] pb-1' />
                            <div className={classNames(
                                'flex flex-col flex-1 pt-3 pr-4 pl-4 w-[calc(100%-0.5rem)] mx-auto rounded text-[0.9375rem]',
                                'bg-[#EDECEE] text-[#716981]'
                            )}>
                                <span className="text-[0.625rem]">{scheduleDay?.breakStartTime?.split(':').slice(0, 2)?.join(':')}</span>
                                <h1 className="font-semibold truncate">Comida</h1>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                )
            })

            schDays = [...schDays, schDay]
            // setEvents([...events, ...schDays])
        })

        setBreakTimes(schDays)
    }, [scheduleDays])

    useEffect(() => {

        let max = 0, min = 24;
        let countdates = {}
        let postiondates = {}
        let evt = []
        /* if (scheduledAppointments?.[0]["2022-03-14"]) {
            scheduledAppointments?.[0]["2022-03-14"].push({ ...scheduledAppointments?.[0]["2022-03-14"][0], objectId: "232432423vwer" })
            scheduledAppointments?.[0]["2022-03-14"].push({ ...scheduledAppointments?.[0]["2022-03-14"][0], objectId: "sdae23fdsf13432" })
            scheduledAppointments?.[0]["2022-03-14"].push({ ...scheduledAppointments?.[0]["2022-03-14"][1], objectId: "dsfsdfsdfsd" })
            scheduledAppointments?.[0]["2022-03-14"].push({ ...scheduledAppointments?.[0]["2022-03-14"][0], objectId: "sdae2fsdfds313432" })
        } */
        scheduledAppointments?.forEach(appointments => {
            const date = Object.keys(appointments)[0]
            appointments[date]?.forEach(e => {
                if (!e?.serviceData?.isFullDay) {
                    if (!countdates[e?.date])
                        countdates[e?.date] = 1
                    else
                        countdates[e?.date]++
                }
            })
        })

        scheduledAppointments?.forEach(appointments => {
            const date = Object.keys(appointments)[0]
            evt = [...evt, ...(appointments[date]?.map(e => {

                if (!e?.serviceData?.isFullDay) {
                    if (!postiondates[e?.date])
                        postiondates[e?.date] = 1
                    else
                        postiondates[e?.date] = postiondates[e?.date] + 1
                }
                const start = new Date(e?.date);
                const end = subMinutes(start, -e?.duration);
                const hs = dateToHour(start);
                const he = dateToHour(end);
                const diff = he - hs

                max = he > max ? he : max
                min = hs < min ? hs : min

                return ({
                    start: hs,
                    diff: diff,
                    positionParts: postiondates[e?.date] - 1,
                    parts: countdates[e?.date],
                    numDay: checkt(start.getDay()),
                    color: 'rgba(144,12,63,0.4)',
                    appointment: e,
                    pwidth: 1,
                    render: (selected, hover, parts) => (
                        <div className={classNames(
                            "h-full flex flex-col cursor-pointer overflow-hidden",
                            hover ? "mt-0" : ""
                        )}
                        >
                            <div className='border-t-[1px] border-tailgreen pb-1' />
                            <div onClick={() => setSelectedEvent(e)} className={classNames(
                                'flex flex-col flex-1 pt-3 pr-4 pl-4 w-[calc(100%-0.5rem)] mx-auto rounded text-[0.9375rem]',
                                e?.objectId === selected?.objectId
                                    ? 'bg-tailgreen text-white'
                                    : 'bg-[#EFF8EF] text-tailgreen'
                            )}>
                                {parts <= 2 || hover ?
                                    <>
                                        <span className="text-[0.625rem]">{getTime(new Date(e?.date))}</span>
                                        <h1 className="font-semibold truncate">{e?.serviceData?.name}</h1>
                                    </>
                                    : null}
                            </div>
                        </div>
                    ),
                    CardContainer: MyCardContainer
                })
            }))]
        })

        if (max === 0 && min === 24 && !hoursConfig) {
            max = 24
            min = 0
        }
        else if (max === 0 && min === 24 && hoursConfig) {
            max = hoursConfig.max
            min = hoursConfig.min
        }
        else if (!hoursConfig) { }
        else {
            max = hoursConfig.max > max ? hoursConfig.max : max
            min = hoursConfig.min < min ? hoursConfig.min : min
        }
        max = Math.ceil(max)
        min = Math.floor(min)
        const hoursweek = new Array(max - min).fill(0).map((_, i) => i + min)
        setHours(hoursweek)
        setEvents([...evt])
        setSelectedEvent(null)
        return () => { }
    }, [scheduledAppointments, hoursConfig])

    useEffect(() => {
        const fnevent = (e) => setHoursConfig(e.detail)
        document.addEventListener('UpdateHours', fnevent)
        return () => { document.removeEventListener('UpdateHours', fnevent) };
    }, [])

    const showModalUpdate = () => {
        document.dispatchEvent(new CustomEvent('ShowModalUpdate', {
            bubbles: true,
            detail: { open: true }
        }))
    }

    return (
        <div className='w-full'>
            {
                hours.length === 0 || days.length === 0 ?
                    <div >

                    </div>
                    :
                    <div className='w-full'>
                        {
                            isMovil ?
                                <div className='w-full flex flex-row text-2xl items-center mb-3 justify-center'>

                                    <div>
                                        <ChevronDoubleLeftIcon
                                            className="h-10 w-10 text-indigo-500 hover:text-indigo-300 cursor-pointer" aria-hidden="true"
                                            onClick={() => setNow(addDays(now, -7))}
                                        ></ChevronDoubleLeftIcon>
                                    </div>
                                    <div>
                                        <ChevronLeftIcon
                                            className="h-10 w-10 text-indigo-500 hover:text-indigo-300 cursor-pointer" aria-hidden="true"
                                            onClick={() => setNow(addDays(now, -1))}
                                        ></ChevronLeftIcon>
                                    </div>
                                    <p className='mx-5'> {days[0].date}</p>
                                    <div>
                                        <ChevronRightIcon
                                            className="h-10 w-10 text-indigo-500 hover:text-indigo-300 cursor-pointer" aria-hidden="true"
                                            onClick={() => setNow(addDays(now, 1))}
                                        ></ChevronRightIcon>
                                    </div>
                                    <div>
                                        <ChevronDoubleRightIcon
                                            className="h-9 w-10 text-indigo-500 hover:text-indigo-300 cursor-pointer" aria-hidden="true"
                                            onClick={() => setNow(addDays(now, 7))}
                                        ></ChevronDoubleRightIcon>
                                    </div>
                                </div>
                                :

                                <div className='w-full sm:flex flex-row text-2xl items-center mb-14 justify-center'>
                                    <button
                                        onClick={showModalUpdate}
                                        className={classNames(
                                            'flex flex-row items-center',
                                            'text-tailgreen hover:text-mediumgreen',
                                            'mr-auto'
                                        )}
                                    >
                                        <CogIcon className='w-10 h-10 mr-5'></CogIcon>
                                        <p className='text-xl font-bold pt-1'>Configuración</p>
                                    </button>
                                    <div className="flex md:absolute md:left-1/2 transform md:-translate-x-1/2 mt-2 sm:mt-0 justify-center sm:justify-end">
                                        <div>
                                            <ChevronLeftIcon
                                                className="h-10 w-10 hover:text-tailgreen cursor-pointer" aria-hidden="true"
                                                onClick={() => setNow(addDays(now, -7))}
                                            ></ChevronLeftIcon>
                                        </div>
                                        <p className='mx-2 text-[2.125rem] font-semibold pt-2'>
                                            {getMonthName(days[0]?.data)}, {getFullYear(days[0]?.data)}
                                        </p>
                                        <div>
                                            <ChevronRightIcon
                                                className="h-10 w-10  hover:text-tailgreen cursor-pointer" aria-hidden="true"
                                                onClick={() => setNow(addDays(now, 7))}
                                            ></ChevronRightIcon>
                                        </div>
                                    </div>
                                </div>
                        }
                        <div className=''>
                            {loading ? (
                                <h2 className="font-semibold text-4xl text-center mt-20">
                                    Cargando...
                                </h2>
                            ) : (
                                <Calendar
                                    days={days}
                                    events={[...breakTimes, ...events]}
                                    selectedEvent={selectedEvent}
                                    setSelectedEvent={setSelectedEvent}
                                    hours={hours}
                                />
                            )}
                        </div>
                    </div>
            }

            <EventDetailModal
                event={selectedEvent}
                onClose={() => setSelectedEvent(null)}
            />
        </div>

    )
}