

import { toast } from "react-toastify"
import { types } from "../models/typeAuth"

export const unauthorizedUser = (status, authAction, message) => {
    if (status === 401) {
        toast.error(message)
        authAction({ type: types.logout })
    }
}
export const errorAlert = (title = "Error", message = "Error desconocido") => toast.error(message)
export const successAlert = (title = "Error", message = "Ok") => toast.success(message)