import { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { types } from '../../models/typeAuth'
import { errorAlert, successAlert } from '../../utils/AlertToast'
import { api } from '../../utils/ApiMethods'

const useLogin = () => {

    const { authAction } = useAuth()

    const [loading, setLoading] = useState(false)

    const handleLogin = async (values) => {

        setLoading(true)

        try {

            const response = await api().post('loginAdmin').sendJson(values)
            const data = await response.json()

            setLoading(false)
            if (response.status === 200) {

                const action = {
                    type: types.login,
                    payload: {
                        user: data,
                        token: data.token
                    }
                }

                successAlert('', 'Bienvenido')
                authAction(action)

            }
            else
                errorAlert('', data.message)

        } catch (e) {

            setLoading(false)
            errorAlert('', 'Error descocido')
            console.log(e)
        }
    }

    return { handleLogin, loading }

}

export default useLogin