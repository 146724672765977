import { Dialog, Switch, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import useGetScheduleStore from '../hooks/stores/useGetScheduleStore'
import useUpdateScheduleStore from '../hooks/stores/useUpdateScheduleStore'
import { validateWeek } from '../schemas/UpdateScheduleStore'
import { classNames } from '../utils/CssHelpers'
import { dateToHour, weekDays } from '../utils/Time'
import DownArrow from '../assets/icons/svg/angle arrow down.svg'
import UpArrow from '../assets/icons/svg/angle arrow up.svg'

export const ModalUpdateShedule = ({ setScheduleDays }) => {

    const getScheduleStore = useGetScheduleStore()
    const updateScheduleStore = useUpdateScheduleStore()

    const cancelButtonModalUpdateRef = useRef(null)
    const [openModalUpdate, setOpenModalDelte] = useState(false)

    const updateScheduleDays = async (days) => {
        setScheduleDays(
            days ? days : await getScheduleStore.handleGetScheduleStore()
        )
    }

    useEffect(() => {
        updateScheduleDays()
        const fnevent = (e) => setOpenModalDelte(e.detail.open)
        document.addEventListener('ShowModalUpdate', fnevent)
        return () => { document.removeEventListener('ShowModalUpdate', fnevent) };
    }, [])

    const [weekvalues, setWeekvalues] = useState(null)

    useEffect(() => {
        if (getScheduleStore.days) {
            updateHours(getScheduleStore.days)
            updateScheduleDays(getScheduleStore.days)
            const monday = { ...getScheduleStore.days.monday }
            monday.isActive = monday.breakEndTime !== null && monday.breakEndTime !== ''
            setWeekvalues({
                days: { ...getScheduleStore.days },
                break: monday,
                ac: new Date
            })
        }
        return () => { };
    }, [getScheduleStore.days])

    const onSubmit = async (values) => {
        const data = {}
        const breakaux = {
            breakStartTime: values.break.isActive ? values.break.breakStartTime : null,
            breakEndTime: values.break.isActive ? values.break.breakEndTime : null,
        }
        weekDays.forEach(d => {
            data[d.day] = { ...values.days[d.day], ...breakaux, isActive: !!values.days[d.day].isActive }
        })
        const success = await updateScheduleStore.handleUpdateScheduleStore(data);
        if (success) {
            updateHours(values.days)
            setOpenModalDelte(false)
        }
    }

    const applyEveryDay = (day, values, setFieldValue) => {
        weekDays.forEach(d => {
            const sday = values.days[d.day]
            if (sday.isActive) {
                setFieldValue(`days.${d.day}.startTime`, day.startTime)
                setFieldValue(`days.${d.day}.endTime`, day.endTime)
                // setFieldValue(`days.${d.day}.sameTimeAppointments`, day.sameTimeAppointments)
            }
        })
    }

    return (
        <>
            {
                weekvalues ?
                    <Formik
                        initialValues={weekvalues}
                        enableReinitialize
                        validate={validateWeek}
                        onSubmit={onSubmit}
                    >
                        {({ isSubmitting, handleChange, setFieldValue, values, onSubmit, resetForm, onCancel, errors, isValid }) => {
                            return (
                                <Transition.Root show={openModalUpdate} as={Fragment}>
                                    <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonModalUpdateRef} onClose={setOpenModalDelte}>
                                        <Form className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block">
                                            <Transition.Child
                                                as={Fragment}
                                                enter="ease-out duration-300"
                                                enterFrom="opacity-0"
                                                enterTo="opacity-100"
                                                leave="ease-in duration-200"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                            </Transition.Child>

                                            {/* This element is to trick the browser into centering the modal contents. */}
                                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                                                &#8203;
                                            </span>
                                            <Transition.Child
                                                as={Fragment}
                                                enter="ease-out duration-300"
                                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                                leave="ease-in duration-200"
                                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                            >
                                                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full">

                                                    <div className='h-[94px] flex items-center px-5 bg-semigray'>
                                                        <Dialog.Title as="h1" className="h1 text-[30px]">
                                                            Configuración
                                                        </Dialog.Title>
                                                        <div className='ml-auto'><XIcon className='h-10 w-10 cursor-pointer hover:text-darkgray' onClick={() => setOpenModalDelte(false)}></XIcon></div>
                                                    </div>
                                                    <div className='px-5'>

                                                        <div className='my-5'>
                                                            <h2 className='h2'>¿Qué días trabajan y en qué horario?</h2>
                                                            <p className='mt-5'>Bloquearemos las horas en las que no se trabaja para que no tengas citas en ese horario.</p>
                                                        </div>

                                                        <div className='flex space-x-5'>
                                                            {
                                                                weekDays.map((d) => (
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => setFieldValue(`days.${d.day}.isActive`, values.days[d.day].isActive === 1 ? 0 : 1)}
                                                                        className={
                                                                            classNames(
                                                                                'w-[121px] rounded-sm text-white text-[18px] py-2 font-semibold',
                                                                                values.days[d.day].isActive ? (isValid ? 'hover:bg-mediumgreen bg-tailgreen' : 'bg-tailgreen') :
                                                                                    'bg-semigray text-gray-600 hover:bg-mediumgray'
                                                                            )}
                                                                        key={d.id}
                                                                        disabled={!isValid}
                                                                    >
                                                                        {d.name}
                                                                    </button>
                                                                ))
                                                            }
                                                        </div>

                                                        <div className="xl:grid hidden grid-cols-2">
                                                            <div className='mt-8'>
                                                                <h2 className='h2'>¿En qué horario trabajan?</h2>
                                                            </div>
                                                            <div className='mt-8'>
                                                                <h2 className='h2'>¿Cuántas citas pueden atender por hora?</h2>
                                                            </div>
                                                        </div>

                                                        <div className="grid xl:grid-cols-2">
                                                            <div>
                                                                <div className='mt-8 xl:hidden'>
                                                                    <h2 className='h2'>¿En qué horario trabajan?</h2>
                                                                </div>
                                                                {
                                                                    weekDays.map(d => !values.days[d.day].isActive ? null : (
                                                                        <div className='flex flex-row items-center my-5' key={"dsad" + d.id}>
                                                                            <p className={
                                                                                classNames(
                                                                                    'w-[100px] text-lg',
                                                                                    values.days[d.day].isActive ? '' : 'text-darkgray'
                                                                                )
                                                                            } >{d.name}</p>
                                                                            <div className='flex flex-row items-center bg-semigray py-2 px-2 rounded-lg'>
                                                                                <Field name={`days.${d.day}.startTime`} type="time" className='multiselect__input bg-semigray' disabled={!values.days[d.day].isActive}></Field>
                                                                                <p className='w-[50px] text-lg text-center'>-</p>
                                                                                <Field name={`days.${d.day}.endTime`} type="time" className='multiselect__input bg-semigray' disabled={!values.days[d.day].isActive}></Field>
                                                                            </div>
                                                                            <div className='flex ml-5'>
                                                                                <ErrorMessage name={`days.${d.day}`} render={Error} />
                                                                                {
                                                                                    !isValid ? null : (
                                                                                        <button
                                                                                            type="button"
                                                                                            className={classNames(
                                                                                                "text-tailgreen hover:text-mediumgreen",
                                                                                                "focus:text-darkgreen"
                                                                                            )}
                                                                                            onClick={() => applyEveryDay(values.days[d.day], values, setFieldValue)}
                                                                                        > Aplicar todos los días </button>
                                                                                    )

                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                            <div className='flex flex-col justify-evenly flex-1 mb-5 xl:mb-0'>
                                                                <div className='mt-5 xl:hidden'>
                                                                    <h2 className='h2'>¿Cuántas citas pueden atender por hora?</h2>
                                                                </div>
                                                                {
                                                                    weekDays.map(d => !values.days[d.day].isActive ? null : (
                                                                        <div className='flex flex-row items-center mt-5 xl:mt-0' key={"dsad" + d.id}>
                                                                            <div
                                                                                className='flex flex-row items-center bg-semigray py-2 px-2 rounded-lg'
                                                                                onClick={() => setFieldValue(`days.${d.day}.sameTimeAppointments`, (values?.days[d?.day]?.sameTimeAppointments > 1 ? (
                                                                                    values?.days[d?.day]?.sameTimeAppointments - 1
                                                                                ) : (
                                                                                    values?.days[d?.day]?.sameTimeAppointments)
                                                                                ))}
                                                                            ><img src={UpArrow} alt="" className="transform rotate-180" /></div>
                                                                            <div className='flex flex-row items-center bg-semigray py-2 px-2 rounded-lg mx-1'>
                                                                                <Field name={`days.${d.day}.sameTimeAppointments`} type="number" className='multiselect__input bg-semigray inp-number max-w-[3.18rem] text-center' min="1" disabled={!values.days[d.day].isActive}></Field>
                                                                            </div>
                                                                            <div
                                                                                className='flex flex-row items-center bg-semigray py-2 px-2 rounded-lg'
                                                                                onClick={() => setFieldValue(`days.${d.day}.sameTimeAppointments`, (values?.days[d?.day]?.sameTimeAppointments + 1))}
                                                                            ><img src={UpArrow} alt="" /></div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <h2 className='h2 my-5'>¿Cuentan con horario de comida?</h2>
                                                            <p>Podemos bloquear este horario para que tus empleados puedan descansar correctamente.</p>
                                                        </div>
                                                        <div className='flex flex-row items-center my-5 h-10'>
                                                            <p className='w-[100px] text-lg'>
                                                                <Switch
                                                                    checked={values.break.isActive}
                                                                    onChange={(() => setFieldValue('break.isActive', values.break.isActive === 1 ? 0 : 1))}
                                                                    className={`${values.break.isActive ? 'bg-tailgreen' : 'bg-darkgray'
                                                                        } relative inline-flex items-center h-6 rounded-full w-11`}
                                                                >
                                                                    <span className="sr-only">Enable notifications</span>
                                                                    <span
                                                                        className={`${values.break.isActive ? 'translate-x-6' : 'translate-x-1'
                                                                            } inline-block w-4 h-4 transform bg-white rounded-full`}
                                                                    />
                                                                </Switch>
                                                            </p>
                                                            {
                                                                values.break.isActive ?
                                                                    <>
                                                                        <div className='flex flex-row items-center bg-semigray py-2 px-2'>
                                                                            <Field name={`break.breakStartTime`} type="time" className='multiselect__input bg-semigray' disabled={!values.break.isActive}></Field>
                                                                            <p className='w-[50px] text-lg text-center'>-</p>
                                                                            <Field name={`break.breakEndTime`} type="time" className='multiselect__input bg-semigray' disabled={!values.break.isActive}></Field>
                                                                        </div>
                                                                        <div className='flex'>
                                                                            <ErrorMessage name={'break'} render={Error} />
                                                                        </div>
                                                                    </>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="flex my-5 flex-col space-y-3 px-5 md:flex-row md:space-y-0 md:space-x-3 md:justify-end">
                                                        <button
                                                            type="button"
                                                            className="btn-secondary w-full md:w-[250px] btn-small"
                                                            onClick={() => setOpenModalDelte(false)}
                                                            ref={cancelButtonModalUpdateRef}
                                                        >
                                                            Cancelar
                                                        </button>

                                                        <button
                                                            className="btn-prymary w-full md:w-[250px] btn-small"
                                                            type="submit"
                                                            disabled={!isValid}
                                                        >
                                                            Guardar
                                                        </button>
                                                    </div>

                                                </div>
                                            </Transition.Child>
                                        </Form>
                                    </Dialog>
                                </Transition.Root>
                            )
                        }}
                    </Formik>
                    : null
            }
        </>
    )
}



const Error = msg => <div className=' text-red-400'>{msg}</div>

const updateHours = (schedule) => {
    try {
        const detail = getMaxMin(schedule)
        document.dispatchEvent(new CustomEvent('UpdateHours', {
            bubbles: true,
            detail
        }))
    } catch (error) {
    }
}

const getMaxMin = (schedule) => weekDays.reduce(
    (a, d) => {
        const start = dateToHour(new Date("01/02/2022 " + schedule[d.day].startTime))
        const end = dateToHour(new Date("01/02/2022 " + schedule[d.day].endTime))
        return ({
            max: a.max > end ? a.max : end,
            min: a.max < start ? a.max : start
        })
    }
    , { max: 0, min: 24 })
