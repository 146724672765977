import { isObjEmpty } from "../utils/Objects";
import { weekDays } from "../utils/Time";

const validateDay = (day) => {
    if (day.startTime === '' || day.startTime === null)
        return 'Valor requerido';
    if (day.endTime === '' || day.endTime === null)
        return 'Valor requerido';
    if (day.startTime && day.endTime) {
        const startTimeDate = new Date('2020-01-01 ' + day.startTime)
        const endTimeDate = new Date('2020-01-01 ' + day.endTime)
        if (startTimeDate.getTime() >= endTimeDate.getTime())
            return 'La hora de inicio no debe ser menor a la final';
    }
}

const validateBreak = (breakaux) => {
    if (breakaux.breakStartTime === '' || breakaux.breakStartTime === null)
        return 'Valor requerido';
    if (breakaux.breakEndTime === '' || breakaux.breakStartTime === null)
        return 'Valor requerido';
    if (breakaux.breakStartTime && breakaux.breakEndTime) {
        const breakStartTimeDate = new Date('2020-01-01 ' + breakaux.breakStartTime)
        const breakEndTimeDate = new Date('2020-01-01 ' + breakaux.breakEndTime)
        if (breakStartTimeDate.getTime() >= breakEndTimeDate.getTime())
            return 'La hora de inicio no debe ser menor a la final';
    }
}

const validateBreakWeek = (day, breakaux) => {
    const breakStartTimeDate = new Date('2020-01-01 ' + breakaux.breakStartTime)
    const breakEndTimeDate = new Date('2020-01-01 ' + breakaux.breakEndTime)
    const startTimeDate = new Date('2020-01-01 ' + day.startTime)
    const endTimeDate = new Date('2020-01-01 ' + day.endTime)

    if (breakEndTimeDate.getTime() >= endTimeDate.getTime())
        return 'La hora de salida de la comida es mayor a la del horario';

    if (breakStartTimeDate.getTime() <= startTimeDate.getTime())
        return 'La hora de entrada de la comida es menor a la del horario';

}

export const validateWeek = values => {

    const errors = {}

    const errosrsweek = {}
    for (let i = 0; i < weekDays.length; i++) {
        const name = weekDays[i].day;
        if (values.days[name].isActive) {
            const error = validateDay(values.days[name])
            if (error)
                errosrsweek[name] = error
        }

    }

    if (values.break.isActive) {
        let error = validateBreak(values.break)
        if (!error && isObjEmpty(errosrsweek)) {
            for (let i = 0; i < weekDays.length; i++) {
                const name = weekDays[i].day;
                if (values.days[name].isActive) {
                    const errorday = validateBreakWeek(values.days[name], values.break)
                    if (errorday) {
                        error = errorday
                        errosrsweek[name] = errorday
                        break
                    }
                }
            }
        }

        if (error)
            errors.break = error
    }

    if (!isObjEmpty(errosrsweek))
        errors.days = errosrsweek

    return errors;
}