import { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { errorAlert, successAlert } from '../../utils/AlertToast'
import { api } from '../../utils/ApiMethods'

const useDeleteService = () => {

    const [loading, setLoading] = useState(false)
    const { auth } = useAuth()

    const handleDeleteService = async (serviceObjectId) => {

        setLoading(true)

        try {

            const response = await api(auth.token).post('deleteService').sendJson({ serviceObjectId })
            const result = await response.json()

            setLoading(false)

            if (response.status === 200) {
                successAlert('', result.message)
                return true
            }
            else
                errorAlert('', result.message)

        } catch (e) {

            errorAlert('', 'Error descocido')
            setLoading(false)

        }

        return false

    }

    return { handleDeleteService, loading }

}

export default useDeleteService