import { NavLink, useParams } from "react-router-dom"
import MessageIcon from '../../assets/icons/svg/message.svg'
import EyeIcon from '../../assets/icons/svg/eye.svg'
import Pencil2Icon from '../../assets/icons/svg/pencil2.svg'
import { Field, Form, Formik } from "formik"
import { classNames } from "../../utils/CssHelpers"
import { appointmentStatuses } from "../../catalogues/AppointmentStatuses"
import useGetAppointmentByIdForProvider from "../../hooks/appointments/useGetAppointmentByIdForProvider"
import useUpdateAppointmentStatus from "../../hooks/appointments/useUpdateAppointmentStatus"
import AvatarPhotodefault from '../../assets/images/png/app/AvatarPhotodefault.png'
import PetPhotoDefault from '../../assets/images/png/app/BgPet.png'
import { useEffect } from "react"
import { formatCurrency } from "../../utils/StringUtils"
import { getAge } from "../../utils/DateUtils"

const inputBG = (touched, error) => (touched) ? (error ?
    "ring-darkred border-darkred focus:border-darkred focus:ring-darkred "
    :
    "ring-tailgreen border-tailgreen focus:border-tailgreen focus:ring-tailgreen"
) : "ring-gray-300 border-gray-300 focus:border-gray-300 focus:ring-gray-300"

export const AppointmentDetailPage = () => {
    const { id } = useParams()
    const { handleGetAppointmentByIdForProvider, appointment } = useGetAppointmentByIdForProvider()
    const updateAppointmentStatus = useUpdateAppointmentStatus()

    const formatPercentage = percentage => {
        return (percentage * 100).toFixed(2)
    }

    const formatDate = (date, options = { month: '2-digit', day: '2-digit', year: 'numeric' }) => {
        return new Date(date).toLocaleDateString('es-ES', options)
    }

    const formatTime = (date, options = { timeStyle: 'short' }) => {
        return new Date(date).toLocaleTimeString('es-ES', options)
    }

    const handleSubmit = async (values) => {
        await updateAppointmentStatus?.handleUpdateAppointmentStatus(values)
    }

    useEffect(() => {
        handleGetAppointmentByIdForProvider({
            appointmentObjectId: id
        })
    }, [])
    return (
        <main className="mt-10">
            <section className="grid lg:grid-cols-3">
                <div className="border-dashed border-r-[1px] border-[#E4E3E5] px-10 pt-10">
                    <div className="flex flex-col h-full">

                        <h2 className="text-2xl font-semibold">Sobre el cliente</h2>
                        <img src={appointment?.userClientData?.pictureData?.fullpath ? appointment?.userClientData?.pictureData?.fullpath : AvatarPhotodefault} alt="" className="rounded-lg h-28 w-28 object-cover my-8" />
                        <table className="w-full table-fixed">
                            <tbody>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Nombre</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{`${appointment?.userClientData?.name} ${appointment?.userClientData?.lastNameP} ${appointment?.userClientData?.lastNameM}`}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Teléfono</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.userClientData?.phoneNumber}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Correo electrónico</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.userClientData?.email}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Dirección</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">
                                        {appointment?.userClientData?.addressData ? (
                                            `${appointment?.userClientData?.addressData?.street} ${appointment?.userClientData?.addressData?.exteriorNumber}, ${appointment?.userClientData?.addressData?.neighborhood}, ${appointment?.userClientData?.addressData?.postalCode}, ${appointment?.userClientData?.addressData?.municipality}, ${appointment?.userClientData?.addressData?.state}`
                                        ) : (
                                            '-'
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <NavLink to="/conversaciones" className="mt-auto pt-16 font-semibold text-tailgreen text-lg flex items-center">
                            <img src={MessageIcon} alt="" className="mr-5" />
                            Enviar mensaje
                        </NavLink>
                    </div>
                </div>
                <div className="border-dashed border-r-[1px] border-[#E4E3E5] px-10 pt-10">
                    <div className="flex flex-col h-full">
                        <h2 className="text-2xl">Sobre la mascota</h2>
                        <img src={appointment?.petData?.pictureData?.fullpath ? appointment?.petData?.pictureData?.fullpath : PetPhotoDefault} alt="" className="rounded-lg h-28 w-28 object-cover my-8" />
                        <table className="w-full table-fixed">
                            <tbody>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Nombre</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.petData?.name}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Tipo de mascota</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.petData?.petTypeData?.name}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Raza</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.petData?.breedData?.name}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Edad</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{getAge(new Date(appointment?.petData?.birthDate ?? null))}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Peso</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.petData?.weight?.toFixed(2) ?? 0} kg</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Género</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.petData?.sex === 1 ? 'Macho' : 'Hembra'}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Alergias</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.petData?.allergy ?? 'N/A'}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Cuidados especiales</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.petData?.specialCares ?? 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                        <NavLink to="/conversaciones" className="mt-auto pt-16 font-semibold text-tailgreen text-lg flex items-center">
                            <img src={EyeIcon} alt="" className="mr-5" />
                            Ver perfil completo
                        </NavLink>
                    </div>
                </div>
                <div className="px-10 pt-10">
                    <div className="flex flex-col h-full">
                        <h2 className="text-2xl">Servicio</h2>
                        <img src={appointment?.serviceData?.picturesData ? appointment?.serviceData?.picturesData[0]?.fullpath : AvatarPhotodefault} alt="" className="rounded-lg h-28 w-28 object-cover my-8" />
                        <table className="w-full table-fixed">
                            <tbody>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Nombre</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.serviceData?.name}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Descripción</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.serviceData?.description}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Folio</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.serviceData?.objectId}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Fecha</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{formatDate(appointment?.date)}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Hora</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{formatTime(appointment?.date)} h</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Categoría</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{appointment?.serviceData?.serviceTypeData?.name}</td>
                                </tr>
                                <tr align="top">
                                    <td valign="top" className="pt-3 text-base font-semibold pr-8">Monto</td>
                                    <td valign="top" className="pt-3 text-base font-semibold text-darkgray">{formatCurrency(appointment?.price)}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="w-80 max-w-full mt-4">
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    appointmentObjectId: appointment?.objectId ?? '',
                                    status: appointment?.status ?? 0
                                }}
                                onSubmit={handleSubmit}
                            >
                                {({ touched, errors }) => (
                                    <Form>
                                        <Field
                                            as="select"
                                            name="status"
                                            className={classNames(
                                                "form-select inp-small block w-full px-4 py-3 border placeholder-darkgray text-gray-900 focus:outline-none bg-semigray",
                                                inputBG(touched.status, errors.status)
                                            )}
                                        >
                                            {Object.values(appointmentStatuses)?.map((status, i) => (
                                                i != 3 ?
                                                <option key={i} value={i}>{status}</option> : ''
                                            ))}
                                        </Field>
                                        <button
                                            className="mt-auto pt-16 font-semibold text-tailgreen text-lg flex items-center"
                                            type="submit"
                                        >
                                            <img src={Pencil2Icon} alt="" className="mr-5" />
                                            Editar estatus
                                        </button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </section>
            <section className="border-t-[1px] border-[#E4E3E5] mt-9 flex">
                <NavLink to="/historico" className="my-9 ml-auto">
                    <button
                        className="btn-prymary w-[250px] btn-small"
                        type="button"
                    >
                        Regresar
                    </button>
                </NavLink>
            </section>
        </main>
    )
}