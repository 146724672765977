import { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { errorAlert, successAlert, unauthorizedUser } from '../../utils/AlertToast'
import { api } from '../../utils/ApiMethods'

const useCreateService = () => {

    const { authAction, auth } = useAuth()
    const [loading, setLoading] = useState(false)

    const handleCreateService = async (values) => {

        setLoading(true)

        try {

            const response = await api(auth.token).post('createService').sendForm({
                storeObjectId: auth?.user?.storeData?.objectId,
                ...values,
            })

            const data = await response.json()

            setLoading(false)

            if (response.status === 201) {
                successAlert('', data.message)
                return true
            }
            else if (response.status === 401)
                unauthorizedUser(authAction, data.message)
            else
                errorAlert('', data.message)

        } catch (e) {

            console.log(e)
            setLoading(false)
            errorAlert('', 'Error descocido')

        }

        return false

    }

    return { handleCreateService, loading }

}

export default useCreateService