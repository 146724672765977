import { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { errorAlert, unauthorizedUser } from '../../utils/AlertToast'
import { api } from '../../utils/ApiMethods'
import { weekDays } from '../../utils/Time'

const useGetScheduleStore = () => {

    const { authAction, auth } = useAuth()

    const [loading, setLoading] = useState(false)
    const [days, setDays] = useState(null)

    const handleGetScheduleStore = async () => {

        setLoading(true)

        try {

            const response = await api(auth.token).getPost('getScheduleStore').sendJson({ storeObjectId: auth?.user?.storeData?.objectId })
            const data = await response.json()

            setLoading(false)

            if (response.status === 200) {

                const days = {}
                weekDays.forEach(d => {
                    const now = data[d.day]
                    days[d.day] = {
                        'weekDayObjectId': d.id,
                        'isActive': now ? now.isActive : true,
                        'startTime': now ? now.startTime : '09:00:00',
                        'endTime': now ? now.endTime : '18:00:00',
                        'breakStartTime': now ? now.breakStartTime : '',
                        'breakEndTime': now ? now.breakEndTime : '',
                        'sameTimeAppointments': now ? now.sameTimeAppointments : 1
                    }
                })
                setDays(days)

                return days

            }
            else if (response.status === 401)
                unauthorizedUser(authAction, data.message)
            else
                errorAlert('', data.message)

        } catch (e) {

            setLoading(false)
            errorAlert('', 'Error descocido')

        }
    }

    return { handleGetScheduleStore, loading, days, setDays }

}

export default useGetScheduleStore