import { Route, Routes } from 'react-router-dom'
import { ForgotPasswordPage } from '../pages/forgotPassword/ForgotPasswordPage'
import { LoginPage } from '../pages/login/LoginPage'
import { PrivateRoute } from './PrivateRoute'
import { PublicRoute } from './PublicRoute'
import { RegisterRoute } from './RegisterRoute'


export const AppRouter = () => {

    return <Routes>
        <Route path="/login" element={
            <PublicRoute>
                <LoginPage></LoginPage>
            </PublicRoute>
        } />
        <Route path="/forgot-password" element={
            <PublicRoute>
                <ForgotPasswordPage></ForgotPasswordPage>
            </PublicRoute>
        } />
        <Route path="/*" element={
            <PrivateRoute>
                <RegisterRoute />
            </PrivateRoute>
        } />
    </Routes>
}