import { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { api } from '../../utils/ApiMethods'

const useGetServices = () => {

    const { auth } = useAuth()

    const [services, setServices] = useState(null)
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(null)
    const [params, setParams] = useState({
        pageNum: 0,
        perPage: 10,
        sortField: 'services.created_at',
        filter: '',
        sortDir: -1,
    })

    const handleGetServices = async () => {

        setLoading(true)

        try {

            const response = await api(auth.token).getPost('getServices').sendJson({
                ...params,
                storeObjectId: auth?.user?.storeData?.objectId
            })

            const result = await response.json()

            if (response.status === 200) {
                const { data, ...rest } = result
                setServices(data ?? [])
                setPagination(rest ?? [])
            }

            setLoading(false)

        } catch (e) {

            setLoading(false)

        }

    }

    return { handleGetServices, loading, services, pagination, params, setParams }

}

export default useGetServices