import { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { errorAlert, unauthorizedUser } from '../../utils/AlertToast'
import { api } from '../../utils/ApiMethods'

const useGetStoreForStore = () => {
    const { authAction, auth } = useAuth()
    const [loading, setLoading] = useState(false)
    const [store, setStore] = useState()

    const handleGetStoreForStore = async () => {
        setLoading(true)
        try {
            const response = await api(auth.token).getPost('getStoreForStore').sendJson({ storeObjectId: auth?.user?.storeData?.objectId })
            const data = await response.json()

            setLoading(false)
            if (response.status === 200) {
                setStore(data)
                return true
            }
            unauthorizedUser(response.status, authAction, data.message)
        } catch (e) {
            setLoading(false)
            errorAlert()
        }
        return false
    }

    return { handleGetStoreForStore, loading, store }

}

export default useGetStoreForStore