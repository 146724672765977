import { GoogleMap, LoadScript, Marker, StandaloneSearchBox } from '@react-google-maps/api';
import React, { useRef, useState } from 'react';
import { getAddressObject } from '../utils/UtilsMaps';
import { classNames } from '../utils/CssHelpers';

const getInfoPosition = (latLng, showResults) => {
    if (window?.google?.maps?.Geocoder) {
        var geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ 'latLng': latLng }, function (results, status) {
            if (status === window.google.maps.GeocoderStatus.OK) {
                showResults(results);
            }
        })
    }
    return null;
}
const centerInit = {
    lat: -3.745,
    lng: -38.523
};

const lib = ['places'];

const fn = (...v) => { console.log(v) }

export const StoreMaps = ({ setFieldValue = fn, center = centerInit, editable = false }) => {

    const inputRef = useRef();
    const [positionMarker, setPositionMarker] = useState(center)
    const [centerMap, setCenterMap] = useState(center)

    const [searchBox, setSearchBox] = useState(null);

    const updatePosition = (lat, lng) => {
        setCenterMap({ lat, lng })
        setPositionMarker({ lat, lng });
    }
    const onPlacesChanged = () => {
        const places = searchBox.getPlaces()
        const latLng = places[0].geometry.location
        updatePosition(latLng.lat(), latLng.lng())
        updateInfo(places, latLng.lat(), latLng.lng())
    };
    const onSBLoad = ref => setSearchBox(ref)
    const onMarkerDragEnd = (coord) => {
        const { latLng } = coord
        getInfoPosition(latLng, (places) => {
            if (inputRef && places)
                if (places[0]?.formatted_address)
                    inputRef.current.value = places[0].formatted_address
            updateInfo(places, latLng.lat(), latLng.lng())
            updatePosition(latLng.lat(), latLng.lng())
        })
    }

    const updateInfo = (places, lat, lng) => {
        if (places && places.length > 0) {
            let address = getAddressObject(places[0].address_components)
            setFieldValue('street', address.street ?? '')
            setFieldValue('exteriorNumber', address.street_number ?? '')
            // setFieldValue('numberExt', address.street_number ?? '')
            setFieldValue('state', address.region ?? '')
            setFieldValue('municipality', address.city ?? '')
            setFieldValue('country', address.country ?? '')
            setFieldValue('longitude', lat ?? '')
            setFieldValue('latitude', lng ?? '')
        }
    }
    return (
        <div className="w-full h-full flex flex-col">
            <LoadScript
                googleMapsApiKey={process.env.REACT_APP_API_API_MAPS ?? ''}
                libraries={lib}
            >
                <StandaloneSearchBox
                    onPlacesChanged={onPlacesChanged}
                    onLoad={onSBLoad}
                >
                    <div>
                        <input
                            type="text"
                            placeholder="Buscar"
                            ref={inputRef}
                            className={classNames(
                                "w-full my-1 rounded hidden",
                                "w-full inp-small px-4 bg-semigray",
                                "inp-small_empty",
                                "ring-tailgreen border-tailgreen focus:border-tailgreen focus:ring-tailgreen",
                                "ring-gray-300 border-gray-300 focus:border-gray-300 focus:ring-gray-300"
                            )}
                        />
                        {/* <label
                            htmlFor="password"
                            className={classNames(
                                'inp-small_label left-4',
                                "text-tailgreen",
                                "text-gray-300"
                            )}>
                            Buscar
                        </label> */}
                    </div>
                </StandaloneSearchBox>
                <div className="relative flex-1 min-h-[200px]">
                    <GoogleMap
                        mapContainerStyle={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '9px',
                            overflow: 'hidden'
                        }}
                        center={centerMap}
                        zoom={10}
                    >
                        <Marker
                            position={positionMarker}
                            // draggable={editable}
                            draggable={false}
                            onDragEnd={onMarkerDragEnd}
                        />
                    </GoogleMap>
                </div>
            </LoadScript>
        </div>
    )
}