import { useState } from "react"

const minHeight = (height = 0, limit = 100) => height < limit ? limit : height

export default function MyCardContainer({ top, height, ...props }) {

    const [hover, setHover] = useState(false)

    return (
        <div
            style={{
                position: 'absolute',
                top: !hover ? top : top + 0,
                height: !hover ? height : minHeight(height),
                left: (!hover ? props.leftCardPart : props.leftCard) + "%",
                width: (!hover ? props.widthCardPart : props.widthCard) + "%",
            }}
            className="z-[9] hover:z-10 hover"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {props.render ? props.render(props.selectedEvent, hover, props.parts) : null}
        </div>
    )
}
