import { useEffect, useState, useRef } from "react";
import { formatHour } from "../../utils/Time";
import Body from "./Body";
import WatchIcon from '../../assets/icons/svg/watch.svg'
import DayNames from "./DayNames";
import Head from "./Head";
import { myFormatDate } from "../../utils/DateUtils";
import { classNames } from "../../utils/CssHelpers";

const filterFullDayAppointments = (day) => {
    return (e) => {
        if (!e?.appointment) return false
        const format = 'dd/MM/yyyy'
        const date1 = myFormatDate(new Date(day?.date), format)
        const date2 = myFormatDate(new Date(e?.appointment?.date), format)
        return date1 === date2 && e?.appointment?.serviceData?.isFullDay
    }
}

const filterDateAppointments = e => {
    return !e?.appointment?.serviceData?.isFullDay
}

export default function Calendar({ hours = [], heightDay = 81, days, styleLine, styleHour = {}, events = [], selectedEvent, setSelectedEvent }) {

    const heightDays = 100
    const heightBody = heightDay * hours.length
    const heightCalendar = heightBody + heightDays;
    const widthCalendar = 100
    const widthHours = 100
    const [fullDayHeight, setFullDayHeight] = useState()
    const fullDayRef = useRef()

    useEffect(() => {
        setFullDayHeight(fullDayRef.current?.clientHeight)
    }, [fullDayRef.current?.clientHeight])

    return (
        <div className="flex flex-row overflow-hidden rounded border-[1px] border-[#E4E3E5]">
            <div>
                <div className="bg-semigray relative border-b-[1px] border-[#E4E3E5]" style={{ height: heightDays }}>
                    <img src={WatchIcon} alt="" className="h-10 absolute transform -translate-y-1/2 -translate-x-1/2 left-1/2 top-1/2" />
                </div>
                <span
                    style={{ height: fullDayHeight }}
                    className="text-white pointer-events-none block"
                ></span>
                <div
                    style={{ width: widthHours, height: heightCalendar - heightDay - 19, position: "relative" }}
                    className="top-0"
                >
                    {
                        hours.map((hour, i) => (
                            <div
                                key={"Hour" + i}
                                style={{
                                    // width: widthHours - 10,
                                    position: 'absolute',
                                    top: heightDay * (i),
                                    height: heightDay,
                                    ...styleHour
                                }}
                                className="w-full"
                            >
                                <p className="text-center relative transform -translate-y-1/2 text-[#716981]">
                                    {formatHour(hour)}
                                </p>
                                {((hours.length - 1) != i) && (
                                    <div
                                        style={{
                                            width: (widthHours - 10) * 0.25
                                        }}
                                        className="h-[1px] bg-[#716981] absolute top-1/2 transform -translate-y-full left-1/2 -translate-x-1/2"
                                    />
                                )}
                            </div>
                        ))
                    }
                </div>
            </div>
            <div style={{ height: heightCalendar }} className="flex-1">
                <DayNames widthCalendar={widthCalendar} heightDays={heightDays} days={days} />
                <div className="flex">
                    {days.map((day, i) => (
                        <div
                            ref={fullDayRef}
                            key={"lineaVertical" + day.name}
                            style={{
                                width: (widthCalendar / days.length) + "%",
                                minHeight: 20,
                                backgroundColor: day.color ?? 'white',
                                borderLeftWidth: 1,
                                borderColor: '#E4E3E5'
                            }}>
                            {events?.filter(filterFullDayAppointments(day))?.map((e, i) => (
                                <div
                                    className="p-1 cursor-pointer"
                                    onClick={() => setSelectedEvent(e?.appointment)}
                                    key={e?.appointment?.objectId}
                                >
                                    <div className={classNames(
                                        "text-[0.9375rem] py-1 px-3 font-bold rounded break-all",
                                        e?.appointment?.objectId === selectedEvent?.objectId
                                            ? 'bg-tailgreen text-white'
                                            : 'bg-[#EFF8EF] text-tailgreen'
                                    )}>
                                        {e?.appointment?.serviceData?.name}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <Body
                    heightDay={heightDay}
                    widthBody={widthCalendar}
                    heightBody={heightBody}
                    // styleLine={{ backgroundColor: "gray" }}
                    days={days} hours={hours}
                    styleLine={styleLine}
                    styleHour={styleHour}
                    events={events?.filter(filterDateAppointments)}
                    selectedEvent={selectedEvent}
                />
            </div>
        </div>
    )
}
