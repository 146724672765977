import { Navigate, Route, Routes } from 'react-router-dom'
import { RegisterRouteLayout } from '../layouts/RegisterRouteLayout'
import { ConversationsPage } from '../pages/conversations/ConversationsPage'
import { HistoricalPage } from '../pages/historical/HistoricalPage'
import { HomePage } from '../pages/home/HomePage'
import { ProfileUserPage } from '../pages/profile/ProfileUserPage'
import { ServiceCreatePage } from '../pages/services/ServiceCreatePage'
import { ServiceEditPage } from '../pages/services/ServiceEditPage'
import { ServicePage } from '../pages/services/ServicePage'
import { ServicesPage } from '../pages/services/ServicesPage'
import { AppointmentDetailPage } from '../pages/historical/AppointmentDetailPage'

export const RegisterRouter = () => (
    <RegisterRouteLayout>
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/perfil" element={<ProfileUserPage />} />
            <Route path="/conversaciones" element={<ConversationsPage />} />
            <Route path="/historico" element={<HistoricalPage />} />
            <Route path="/historico/:id" element={<AppointmentDetailPage />} />
            <Route path="/servicios" element={<ServicesPage />} />
            <Route path="/servicios/:id" element={<ServicePage />} />
            <Route path="/servicios/:id/editar" element={<ServiceEditPage />} />
            <Route path="/servicios/crear" element={<ServiceCreatePage />} />
            <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
    </RegisterRouteLayout>
)