import { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { errorAlert, unauthorizedUser } from '../../utils/AlertToast'
import { api } from '../../utils/ApiMethods'

const useGetScheduledAppointments = () => {

    const { authAction, auth } = useAuth()
    const [loading, setLoading] = useState(false)
    const [scheduledAppointments, setScheduledAppointments] = useState()

    const handleGetScheduledAppointments = async (values) => {
        setLoading(true)
        try {
            const response = await api(auth.token)
                .getPost('getScheduledAppointments')
                .sendJson({
                    storeObjectId: auth?.user?.storeData?.objectId,
                    ...values
                })
            const data = await response.json()

            setLoading(false)
            if (response.status === 200) {
                setScheduledAppointments(data)
                return true
            }
            unauthorizedUser(response.status, authAction, data.message)
        } catch (e) {
            setLoading(false)
            errorAlert()
        }

        return false
    }

    return { handleGetScheduledAppointments, loading, scheduledAppointments }

}

export default useGetScheduledAppointments