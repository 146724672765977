import CardContainer from "./CardContainer"



export default function Body({ widthBody, heightBody, heightDay, days, hours, styleLine, events, selectedEvent }) {

    const minHeight = heightBody < 5
    const widthDay = widthBody / days.length
    const startHour = hours[0] ?? 0

    return (

        <div className="flex flex-row" style={{ width: widthBody + '%', height: heightBody, position: "relative" }}>

            {days.map((day, i) => (
                <div
                    key={"lineaVertical" + day.name}
                    style={{
                        width: widthDay + "%",
                        height: heightBody,
                        backgroundColor: day.color ?? 'white',
                        borderLeftWidth: 1,
                        borderColor: '#E4E3E5'
                    }}>

                </div>
            ))}

            {[...hours, hours[hours.length - 1] + 1].map((hour, i) => (
                <div
                    key={"lineaHorizontal" + hour}
                    style={{
                        width: widthBody + "%",
                        position: 'absolute',
                        top: heightDay * i,
                        zIndex: 8,
                        height: 1,
                        backgroundColor: "#E4E3E5",
                        ...styleLine
                    }}>

                </div>
            ))}

            {events.map((event, i) => {

                const pwidth = event.pwidth ?? 0.8
                const parts = event.parts ?? 1
                const positionParts = event.positionParts ?? 0
                const cartParts = event.cartParts ?? 1
                const widthCard = widthDay * pwidth
                const widthCardPart = (widthCard / parts) * cartParts
                const leftCard = ((widthDay - widthCard) / 2 + widthDay * (event.numDay ?? 0))
                const leftCardPart = leftCard + widthCardPart * positionParts
                const CardContainerAux = event.CardContainer ?? CardContainer

                return (
                    <CardContainerAux
                        key={i}
                        top={(event.start - startHour) * heightDay}
                        height={event.diff * heightDay}
                        leftCard={leftCard}
                        widthCard={widthCard}
                        widthCardPart={widthCardPart}
                        leftCardPart={leftCardPart}
                        selectedEvent={selectedEvent}
                        positionParts={positionParts}
                        parts={parts}
                        render={event.render}
                    />
                )
            })}
        </div>

    )
}
