import { ChevronLeftIcon, ChevronRightIcon, PlusIcon, PencilIcon, TrashIcon } from '@heroicons/react/solid'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'

const error = msg => <div className=' text-red-400'>{msg}</div>

const urlimg = "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7f/File-Explorer-fluent-icon.png/250px-File-Explorer-fluent-icon.png"
export const ServicePage = () => {

    return (

        <div className="flex flex-col mb-10">

            <Formik
                initialValues={{
                    email: '',
                    password: '',
                }}
                onSubmit={(values) => console.log(values)}
            >
                {() => (
                    <Form>
                        <div className="grid grid-cols-1 gap-10 lg:grid-cols-2">
                            <div className='col-span-1'>
                                <div>
                                    <h2 className='text-lg font-semibold'>Detalles del servicio</h2>
                                    <div className='grid grid-cols-3 gap-4 mt-5'>
                                        <p className=' font-semibold'>Folio:</p>
                                        <p className='col-span-2'>S0001000</p>
                                    </div>
                                    <div className='grid grid-cols-3 gap-4 mt-5'>
                                        <p className=' font-semibold'>Fecha del servicio::</p>
                                        <p className='col-span-2'>S0001000</p>
                                    </div>
                                    <div className='grid grid-cols-3 gap-4 mt-5'>
                                        <p className=' font-semibold'>Hora del servicio:</p>
                                        <p className='col-span-2'>S0001000</p>
                                    </div>
                                    <div className='grid grid-cols-3 gap-4 mt-5'>
                                        <p className=' font-semibold'>Descripción del servicio:</p>
                                        <p className='col-span-2'>S0001000</p>
                                    </div>
                                    <div className='grid grid-cols-3 gap-4 mt-5'>
                                        <p className=' font-semibold'>Categoría del servicio:</p>
                                        <p className='col-span-2'>S0001000</p>
                                    </div>
                                    <div className='grid grid-cols-3 gap-4 mt-5'>
                                        <p className=' font-semibold'>Monto:</p>
                                        <p className='col-span-2'>S0001000</p>
                                    </div>
                                    <div className='grid grid-cols-3 gap-4 mt-5 items-center'>
                                        <p className='font-semibold'>Estatus del servicio</p>

                                        <div className='col-span-2'>
                                            <label htmlFor="" className="sr-only">
                                                Recibido
                                            </label>
                                            <Field
                                                as="select"
                                                name="s1"
                                                className="appearance-none rounded-xl block w-full px-3 py-2 border text-gray-900 focus:outline-none focus:ring-tailgreen focus:border-tailgreen focus:z-10 sm:text-sm bg-semigray border-gray-300"
                                            >
                                                <option value="">Recibido</option>
                                                <option value="">Recibido</option>
                                                <option value="">Recibido</option>
                                            </Field>

                                            <ErrorMessage name={"email"} render={error} />
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-10'>
                                    <h2 className='text-lg font-semibold'>Detalles del cliente </h2>
                                    <div className='grid grid-cols-3 gap-4 mt-5'>
                                        <p className=' font-semibold'>Nombre del cliente:</p>
                                        <p className='col-span-2'>S0001000</p>
                                    </div>
                                    <div className='grid grid-cols-3 gap-4 mt-5'>
                                        <p className=' font-semibold'>Teléfono:</p>
                                        <p className='col-span-2'>S0001000</p>
                                    </div>
                                    <div className='grid grid-cols-3 gap-4 mt-5'>
                                        <p className=' font-semibold'>Email:</p>
                                        <p className='col-span-2'>S0001000</p>
                                    </div>
                                    <div className='grid grid-cols-3 gap-4 mt-5'>
                                        <p className=' font-semibold'>Foto de perfil:</p>
                                        <div>
                                            <div className='w-20 h-20 rounded-full bg-green-700'></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=' col-span-1'>

                                <div className='mt-10 lg:mt-0'>
                                    <h2 className='text-lg font-semibold'>Detalles de la mascota</h2>
                                    <div className='grid grid-cols-3 gap-4 mt-5'>
                                        <p className=' font-semibold'>Nombre</p>
                                        <p className='col-span-2'>S0001000</p>
                                    </div>
                                    <div className='grid grid-cols-3 gap-4 mt-5'>
                                        <p className=' font-semibold'>Tipo de mascota</p>
                                        <p className='col-span-2'>S0001000</p>
                                    </div>
                                    <div className='grid grid-cols-3 gap-4 mt-5'>
                                        <p className=' font-semibold'>Raza:</p>
                                        <p className='col-span-2'>S0001000</p>
                                    </div>
                                    <div className='grid grid-cols-3 gap-4 mt-5'>
                                        <p className=' font-semibold'>Edad:</p>
                                        <p className='col-span-2'>S0001000</p>
                                    </div>
                                    <div className='grid grid-cols-3 gap-4 mt-5'>
                                        <p className=' font-semibold'>Peso:</p>
                                        <p className='col-span-2'>S0001000</p>
                                    </div>
                                    <div className='grid grid-cols-3 gap-4 mt-5'>
                                        <p className=' font-semibold'>Genero:</p>
                                        <p className='col-span-2'>S0001000</p>
                                    </div>
                                    <div className='grid grid-cols-3 gap-4 mt-5'>
                                        <p className=' font-semibold'>Genero:</p>
                                        <p className='col-span-2'>S0001000</p>
                                    </div>
                                    <div className='grid grid-cols-3 gap-4 mt-5'>
                                        <p className=' font-semibold'>Alergias:</p>
                                        <p className='col-span-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia ratione voluptas aliquam veritatis delectus ad deserunt. Ipsa nobis, libero maxime cupiditate quisquam rerum! Dolorum quidem provident odit reprehenderit, velit eius.</p>
                                    </div>
                                    <div className='grid grid-cols-3 gap-4 mt-5'>
                                        <p className=' font-semibold'>Cuidados especiales:</p>
                                        <p className='col-span-2'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nostrum dolorum beatae fugiat ducimus, quisquam cumque deleniti nam ratione, repellendus tempora numquam ullam aliquid ab aperiam? Nihil quo nostrum alias ea?</p>
                                    </div>
                                    <div className='grid grid-cols-3 gap-4 mt-5'>
                                        <p className=' font-semibold'>Discapacidades:</p>
                                        <p className='col-span-2'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Labore, atque.</p>
                                    </div>
                                    <div className='w-full flex mt-5 flex-col items-center'>

                                        <div className='w-20 h-20 rounded-full bg-green-700 my-5'></div>

                                        <button className='border bg-green-gradient rounded-full font-bold py-2 text-xs text-white w-40'>ver perfil completo</button>

                                    </div>
                                </div>

                                <div className='flex items-center mt-10 space-x-5 justify-center'>
                                    <button className='border border-tailgreen text-tailgreen w-full rounded-full font-bold py-2 text-xs hover:bg-green-100'>Cancelar</button>
                                    <button className='border bg-green-gradient w-full rounded-full font-bold py-2 text-xs text-white'>Guardar</button>
                                </div>

                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}