import React, { useEffect, useRef, useState } from 'react'
import { classNames } from '../../utils/CssHelpers'
import AvatarPhotodefault from '../../assets/images/png/app/AvatarPhotodefault.png'
import PlaneIcon from '../../assets/icons/svg/plane.svg'
import SearchInput from '../../components/SearchInput'
import useGetConversationsForStore from '../../hooks/chat/useGetConversationsForStore'
import useGetConversationForStore from '../../hooks/chat/useGetConversationForStore'
import { useAuth } from '../../context/AuthContext'
import { Field, Form, Formik } from 'formik'
import useSendMessageForStore from '../../hooks/chat/useSendMessageForStore'
import FieldInput from '../../components/FieldInput'
import { formatDistance, differenceInDays, format, subDays } from 'date-fns'
import { es } from 'date-fns/locale'
import { useInView } from 'react-intersection-observer'
import { useDebounce } from '../../hooks/utils/useDebounce'
import { formatDate } from '../../utils/DateUtils'

const ClientMessages = ({ messages, user }) => {
    return (
        <div className="flex mt-12">
            <img src={getImage(user?.pictureData?.fullpath)} alt={user?.name ?? ''} className="h-12 w-12 mr-5 rounded-full object-cover" />
            <ul className="flex-1">
                {messages?.map(msg => (
                    <li key={msg?.objectId} className="mt-2">
                        <ClientMessage title={formatDate(msg?.created_at)}>
                            {msg?.content}
                        </ClientMessage>
                    </li>
                ))}
                <li className="text-xs font-normal text-darkgray mt-2">
                    {formatDate(new Date(messages?.at(-1)?.created_at))}
                </li>
            </ul>
        </div>
    )
}
const StoreMessages = ({ messages }) => {
    const { auth } = useAuth()
    const store = auth?.user?.storeData

    return (
        <div className="flex mt-12">
            <ul className="flex-1">
                {messages?.map(msg => (
                    <li key={msg?.objectId} className="mt-2 text-right">
                        <StoreMessage title={formatDate(msg?.created_at)}>
                            {msg?.content}
                        </StoreMessage>
                    </li>
                ))}
                <li className="text-xs font-normal text-darkgray mt-2 text-right">
                    {formatDate(new Date(messages?.at(-1)?.created_at))}
                </li>
            </ul>
            <img src={getImage(store?.logopath)} alt={store?.tradeName ?? ''} className="h-12 w-12 ml-5 rounded-full object-cover" />
        </div>
    )
}

const ClientMessage = ({ children, ...props }) => {
    return (
        <span
            className="inline-block py-[0.4375rem] px-[1.125rem] rounded-lg text-darkblue font-normal text-base bg-white "
            {...props}
        >
            {children}
        </span>
    )
}
const StoreMessage = ({ children, ...props }) => {
    return (
        <span
            className="inline-block py-[0.4375rem] px-[1.125rem] rounded-lg text-darkblue font-normal text-base bg-lightgreen"
            {...props}
        >
            {children}
        </span>
    )
}

const ConversationCard = ({ conversation, onClick, selectedConversation }) => {
    return (
        <div
            onClick={() => onClick({ userClientObjectId: conversation?.userData?.objectId })}
            className={classNames(
                "border-[#E4E3E5] border-t-[1px] p-[1.125rem] flex cursor-pointer h-[6.75rem]",
                selectedConversation?.objectId === conversation?.objectId ? 'bg-semigray' : 'bg-semiwhite'
            )}
        >
            <img src={getImage(conversation?.userData?.pictureData?.fullpath)} alt={conversation?.userData?.name} className="h-[4.375rem] w-[4.375rem] object-cover rounded-full mr-[1.125rem]" />
            <div className="flex-1 pr-2 overflow-hidden">
                <p className="font-semibold truncate text-ellipsis break-all" title={`${conversation?.userData?.name} ${conversation?.userData?.lastNameP} ${conversation?.userData?.lastNameM}`}>
                    {`${conversation?.userData?.name} ${conversation?.userData?.lastNameP} ${conversation?.userData?.lastNameM}`}
                </p>
                <p className="font-normal text-extradarkgray break-all truncate text-ellipsis">
                    {conversation?.lastMessageData?.content}
                </p>
                <p className="text-[0.875rem] text-darkgray">
                    {formatDate(new Date(conversation?.lastMessageData?.created_at))}
                </p>
            </div>
            <span className={classNames(
                "bg-mediumgreen text-white w-7 h-7 rounded-full relative",
                conversation?.messagesNotRead ? 'visible' : 'invisible'
            )}>
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 leading-7 align-middle mt-[0.1rem] text-center">
                    {conversation?.messagesNotRead}
                </div>
            </span>
        </div>
    )
}

const UserCard = ({ user }) => {
    return (
        <div className="col-span-2 hidden md:flex lg:col-span-1 bg-mediumgray h-full p-9 flex-col">
            <img src={getImage(user?.pictureData?.fullpath)} alt={user?.name} className="h-40 w-40 mx-auto rounded-full object-cover mb-5" />
            <h2 className="font-semibold text-xl text-center mb-9">
                {`${user?.name} ${user?.lastNameP} ${user?.lastNameM}`}
            </h2>
            <table className="w-full">
                <tbody>
                    <tr>
                        <td valign="top" className="font-semibold pr-7 pb-7 text-[#1A051D]">Teléfono</td>
                        <td valign="top" className="text-darkgray pb-7 break-all">{user?.phoneNumber}</td>
                    </tr>
                    <tr>
                        <td valign="top" className="font-semibold pr-7 pb-7 text-[#1A051D]">Correo</td>
                        <td valign="top" className="text-darkgray pb-7 break-all">{user?.email}</td>
                    </tr>
                    <tr>
                        <td valign="top" className="font-semibold pr-7 pb-7 text-[#1A051D]">Mascotas</td>
                        <td valign="top" className="text-darkgray pb-7">{(user?.petsData?.map(({ name }) => name) ?? [])?.join(' - ')}</td>
                    </tr>
                </tbody>
            </table>

            <p className="text-[#18A0FB] text-center my-auto flex-1 flex justify-center items-center">
                Recomendación: No prescribir o recomendar medicamentos sin antes haber atendido a la mascota en tu veterinaria.
            </p>
        </div>
    )
}

const getImage = (picture) => {
    return picture || AvatarPhotodefault
}

const LoadNewMessages = ({ pagination, onInView }) => {
    const { ref, inView } = useInView({
        threshold: 0.5
    })
    const previous = pagination?.prev_page_url ? true : false

    if (inView && previous)
        console.log({ previous })
    // onInView(pagination?.current_page - 1)

    return <div ref={ref} />
}

const LoadOldMessages = ({ pagination, onInView }) => {
    const [loaded, setLoaded] = useState(false)
    const { ref, inView } = useInView({
        threshold: 0.5
    })
    const next = pagination?.next_page_url ? true : false

    if (inView && next) {
        // onInView(pagination?.current_page + 1)
        console.log({ next })
    }

    return <div ref={ref} />
}

export const ConversationsPage = () => {
    const messagesRef = useRef()
    const {
        loading: loadingConversations,
        setParams: setConversationsParams,
        params: conversationsParams,
        conversations,
        handleGetConversations
    } = useGetConversationsForStore()
    const {
        loading: loadingConversation,
        conversation,
        handleGetConversation,
        params: conversationParams,
        setParams: setConversationParams,
        pagination: conversationPagination
    } = useGetConversationForStore()
    const { handleSendMessageForStore } = useSendMessageForStore()
    const [selectedConversation, setSelectedConversation] = useState()
    const [search, setSearch] = useState('')
    const fieldSearch = { value: search, onChange: (e) => setSearch(e.target.value) }
    const debouncedSearch = useDebounce(search, 500);

    const onInView = (p) => {
        setConversationParams({
            ...conversationParams,
            pageNum: (p - 1)
        })
    }

    const reverseArray = (array = []) => {
        const reversedArray = []
        const arrLen = array?.length ?? 0
        for (let i = arrLen - 1; i >= 0; --i) {
            reversedArray.push(array[i])
        }
        return reversedArray
    }
    const groupConversation = (conversation) => {
        if (!conversation) return null

        const groups = []
        const reversedConversation = reverseArray(conversation)
        reversedConversation?.forEach(c => {
            const lastGroup = groups?.at(-1)
            if (!lastGroup) {
                groups.push([c])
                return
            }
            if (lastGroup[0]?.userObjectId && c?.userObjectId) {
                lastGroup.push(c)
                return
            }

            if (lastGroup[0]?.storeObjectId && c?.storeObjectId) {
                lastGroup.push(c)
                return
            }

            groups.push([])
            const nextlastGroup = groups?.at(-1)
            nextlastGroup.push(c)

        })

        return groups
    }

    const groupedConversation = groupConversation(conversation)
    const goToBottom = (elRef) => {
        if (elRef?.current) {
            elRef?.current?.scrollTo(0, elRef?.current?.scrollHeight)
        }
    }

    const handleSubmit = async (values, { resetForm }) => {
        resetForm()
        if (!values?.message) return
        await handleSendMessageForStore(values)
        await handleGetConversation({ userClientObjectId: selectedConversation?.userData?.objectId })
        handleGetConversations()
    }

    const sortConversations = (a, b) =>
        new Date(b?.lastMessageData?.created_at) - new Date(a?.lastMessageData?.created_at)

    if (groupConversation) goToBottom(messagesRef)

    useEffect(() => {
        // goToBottom(messagesRef)
    }, [conversation])

    useEffect(() => {
        setConversationsParams({
            ...conversationsParams,
            filter: debouncedSearch,
            pageNum: 0
        })
        return () => { };
    }, [debouncedSearch])

    useEffect(() => {
        handleGetConversations()
        // console.log(formatDistance(subDays(new Date(), 3), new Date(), { addSuffix: true, locale: es }))
    }, [conversationsParams])

    return (
        <div>
            {/* chat height: 54.3125rem*/}
            {/* title section height: 6.75rem */}
            {/* max-h-screen*/}
            <div className="grid md:grid-cols-3 lg:grid-cols-4 rounded-2xl border-[#E4E3E5] border-[1px] overflow-hidden">
                <div className="bg-semiwhite col-span-4 md:col-span-1 border-r-[1px] border-[#E4E3E5]">
                    <div className="flex items-center px-7 h-[6.75rem]">
                        <SearchInput
                            className="w-full"
                            field={fieldSearch}
                            meta={{ touched: search }}
                            label='Buscar'
                        />
                    </div>
                    <div>
                        <div className="overflow-y-auto overflow-hidden h-[calc(54rem-6.75rem)]">
                            {loadingConversations && (
                                <div className='flex justify-center my-8'>
                                    <p className='h2'> Cargando...</p>
                                </div>
                            )}
                            {conversations?.sort(sortConversations)?.map(c => (
                                <ConversationCard
                                    key={c?.objectId}
                                    conversation={c}
                                    onClick={async (con) => {
                                        handleGetConversation(con)
                                        setSelectedConversation(c)
                                    }}
                                    selectedConversation={selectedConversation}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="bg-semigray md:col-span-2 lg:col-span-3 h-full">
                    {selectedConversation && (
                        <div className="flex items-center px-7 h-[6.75rem]">
                            <img src={getImage(selectedConversation?.userData?.pictureData?.fullpath)} alt={selectedConversation?.userData?.name} className="h-[4.375rem] w-[4.375rem] object-cover rounded-full mr-7" />
                            <span className="font-semibold text-xl">{`${selectedConversation?.userData?.name
                                } ${selectedConversation?.userData?.lastNameP
                                } ${selectedConversation?.userData?.lastNameM
                                }`}</span>
                        </div>
                    )}
                    <div className={classNames(
                        conversation && 'border-t-[1px]',
                        "grid grid-cols-3 border-[#E4E3E5]"
                    )}>
                        <div className="col-span-3 lg:col-span-2">
                            <div className={classNames(
                                conversation && 'border-b-[1px]',
                                "p-7 overflow-hidden overflow-y-auto h-[calc(54rem-13.5rem)] border-[#E4E3E5]"
                            )} ref={messagesRef}>
                                {(conversation && !loadingConversation) && (
                                    <LoadOldMessages
                                        pagination={conversationPagination}
                                        onInView={onInView}
                                    />
                                )}

                                {loadingConversation && (
                                    <div className='flex justify-center my-8'>
                                        <p className='h2'> Cargando...</p>
                                    </div>
                                )}

                                {groupedConversation?.map((group, i) => (
                                    group[0]?.storeObjectId
                                        ? <ClientMessages key={i} messages={group} user={selectedConversation?.userData} />
                                        : <StoreMessages key={i} messages={group} />
                                ))}

                                {conversation && (
                                    <LoadNewMessages
                                        pagination={conversationPagination}
                                        onInView={onInView}
                                    />
                                )}
                            </div>
                            {conversation && (
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        userObjectId: selectedConversation?.userData?.objectId ?? '',
                                        message: ''
                                    }}
                                    onSubmit={handleSubmit}
                                >
                                    {() => (
                                        <Form>
                                            {/* <Field placeholder="Enviar un mensaje" name="message" type="text" className="w-full h-[4.375rem] mt-7" /> */}
                                            <div className="px-7 h-[6.75rem] flex items-center">
                                                <Field
                                                    name="message"
                                                >
                                                    {
                                                        (atrs) => <FieldInput
                                                            {...atrs}
                                                            white
                                                            label="Enviar un mensaje"
                                                            className="flex-1 mr-4"
                                                            type="text"
                                                        ></FieldInput>
                                                    }
                                                </Field>
                                                <button
                                                    type="submit"
                                                >
                                                    <img src={PlaneIcon} alt="" className="w-9 h-8" />
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            )}
                        </div>
                        {conversation && (
                            <UserCard user={selectedConversation?.userData} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}