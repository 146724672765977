export const capitalize = str => {
    const string = str ? str : ''
    const firstLetter = string?.slice(0, 1)
    const restLetters = string?.slice(1)

    return `${firstLetter?.toUpperCase()}${restLetters?.toLowerCase()}`
}

export const capitalizeAll = str => {
    const string = str ? str : ''
    return string.split(' ')
        .map(word => capitalize(word))
        .join(' ')
}

export const formatCurrency = amount => {
    const options = { style: 'currency', currency: 'USD' };
    const numberFormat = new Intl.NumberFormat('en-US', options);

    return numberFormat.format(amount ?? 0);
}