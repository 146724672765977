export const toTime = (d = new Date()) => d.toLocaleString('es', { hour: '2-digit', minute: '2-digit' })

export const toDate = (d = new Date()) => d.toLocaleString('es', { day: '2-digit', month: '2-digit', year: '2-digit', })

export const dayToMM = (day) => (day * 1000 * 60 * 60 * 24)

export const addDays = (d = new Date(), days) => (new Date(d.getTime() + dayToMM(days)))

export const getInitDayWeek = (d = new Date()) => addDays(d, -d.getDay())

export const checkt = (d) => d === 0 ? -7 : d - 1;

export const getInitDayWeekT = (d = new Date()) => addDays(d, - checkt(d.getDay()))

export const formatHour = (h) => (h < 10 ? `0${h}:00` : `${h}:00`)

export const dateToHour = (d) => d.getHours() + (d.getMinutes() / (60))

export const weekDays = [
    { day: 'monday', id: '1ec82db4-3731-6bf0-a71b-2cf05d694255', name: 'Lunes', shorname: 'L' },
    { day: 'tuesday', id: '1ec82db4-b91b-6666-a12b-2cf05d694255', name: 'Martes', shorname: 'Ma' },
    { day: 'wednesday', id: '1ec82db5-66dc-60ca-bdde-2cf05d694255', name: 'Miércoles', shorname: 'Mi' },
    { day: 'thursday', id: '1ec82db7-1dc5-67cc-ae1e-2cf05d694255', name: 'Jueves', shorname: 'J' },
    { day: 'friday', id: '1ec82db7-7d5b-6876-b667-2cf05d694255', name: 'Viernes', shorname: 'V' },
    { day: 'saturday', id: '1ec82db7-d5f0-6e8c-87e3-2cf05d694255', name: 'Sábado', shorname: 'S' },
    { day: 'sunday', id: '1ec82db8-2d88-6b4a-bf42-2cf05d694255', name: 'Domingo', shorname: 'D' }
]